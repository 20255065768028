import '../Animations/heartbeat.css';
import '../Animations/heartbeat.css';

import {
	AcUnitRounded,
	AirRounded,
	CloudRounded,
	FavoriteRounded,
	FlashOnRounded,
	GrassRounded,
	LocalLibrary,
	LooksRounded,
	ParkRounded,
	RocketLaunchRounded,
	SaveAlt,
	SelfImprovement,
	SentimentSatisfiedAlt,
	SentimentVeryDissatisfied,
	Spa,
	SportsGymnastics,
	StarRounded,
	WaterDropRounded,
	WbSunnyRounded,
	YardRounded
} from '@mui/icons-material';
import { AppDispatch, RootState, SageThoughts, Thought } from './Redux/Schema';
import { Button, Divider, Grid, Typography } from '@mui/material';
import {
	GradientCloudIcon,
	MaterialSymbolsAirRounded,
	MaterialSymbolsDirectionsRunRounded,
	MeteoconsLightningBoltFillAnimated,
	SolarDumbbellBold
} from '../assets/svgFiles';
import { UUID, v4 as uuidv4 } from 'uuid';

import { Dispatch } from 'redux';
import { ReactNode } from 'react';
import SectionHeaderTypography from '../components/atoms/SectionHeaderTypography';
import { addThought } from './Redux/Middleware';
import air from '../assets/gifs/air.gif';
import bodyImg from '../assets/images/svg-files/body.svg';
import cloud_gradient from '../assets/images/svg-files/cloud_gradient.svg';
import dumbbellImg from '../assets/images/svg-files/dumbbell.svg';
import { format } from 'date-fns-tz';
import heartImg from '../assets/images/svg-files/heart.svg';
import heartbeatGif from '../assets/gifs/heartbeat.gif';
import { postThought } from './Redux/AsyncMiddleware';
// import rainbow from '../assets/images/svg-files/rainbow.svg';
import rocket from '../assets/images/svg-files/rocket.svg';
import running from '../assets/gifs/running.gif';
import runningImg from '../assets/running.png';
import sageCircle from '../assets/images/logo/sageCircle.svg';
import spiritImg from '../assets/images/svg-files/spirit.svg';
import { useSelector } from 'react-redux';

export const POSITIVE_EMOTIONS = [
	'Admiration',
	'Adoration',
	'Altruism',
	'Amusement',
	'Awe',
	'Calmness',
	'Connection',
	'Contentment',
	'Curiosity',
	'Determination',
	'Ecstasy',
	'Euphoria',
	'Excitement',
	'Freedom',
	'Gratitude',
	'Hope',
	'Intrigue',
	'Joy', // Primary
	'Love',
	'Passion',
	'Pride',
	'Quiescence',
	'Relief',
	'Serenity',
	'Surprise' // Primary
];

export const NEGATIVE_EMOTIONS = [
	'Anger', // Primary
	'Anguish',
	'Annoyance',
	'Apathy',
	'Bitterness',
	'Boredom',
	'Calamity',
	'Confusion',
	'Craving',
	'Crushedness',
	'Desperation',
	'Disappointment',
	'Disgust', // Primary
	'Dread',
	'Embarrassment',
	'Envy',
	'Exhaustion',
	'Fear', // Primary
	'Frustration',
	'Grief',
	'Guilt',
	'Hatred',
	'Heartache',
	'Hostility',
	'Humiliation',
	'Hurt',
	'Inadequacy',
	'Indifference',
	'Insecurity',
	'Irritation',
	'Jealousy',
	'Listlessness',
	'Loneliness',
	'Longing',
	'Lust',
	'Melancholy',
	'Nervousness',
	'Nostalgia',
	'Panic',
	'Pity',
	'Rage',
	'Regret',
	'Rejection',
	'Remorse',
	'Resentment',
	'Sadness', // Primary
	'Shame',
	'Shock',
	'Shyness',
	'Sorrow',
	'Stagnation',
	'Stress',
	'Terror',
	'Trepidation',
	'Uncertainty',
	'Unease',
	'Unhappiness',
	'Unrest',
	'Vengefulness',
	'Vulnerability',
	'Worry'
];

export const COGNITIVE_BIAS_DESCRIPTOR: { [key: string]: string } = {
	'Confirmation Bias':
		"The tendency to seek, interpret, and remember information that confirms one's pre-existing beliefs, while ignoring contradictory evidence.",

	'Availability Heuristic':
		'A mental shortcut that relies on immediate examples that come to mind when evaluating a situation or decision, often skewing perception of probability or importance.',

	'Anchoring Bias':
		"The human tendency to rely heavily on the first piece of information encountered (the 'anchor') when making decisions, even if it's irrelevant or arbitrary.",

	'Hindsight Bias':
		'The inclination to see past events as having been predictable after they have already occurred, often leading to an oversimplified view of outcomes.',

	'Availability Cascade':
		'A self-reinforcing cycle in which a belief gains increasing plausibility through its repetition in public discourse, regardless of its accuracy.',

	'Overconfidence Bias':
		"The tendency to be overly sure about the accuracy of one’s knowledge or abilities, often leading to risky decisions or overestimation of one's competence.",

	'Self-Serving Bias':
		'The tendency to attribute successes to personal factors while blaming failures on external factors, protecting self-esteem and self-image.',

	'Loss Aversion':
		'The cognitive bias where losses are perceived as more significant than gains of the same amount, making people more risk-averse to avoid loss rather than seeking equivalent gains.',

	'Sunk Cost Fallacy':
		'The tendency to continue investing in a decision, project, or situation based on prior investments of time, money, or effort, rather than current rational evaluation.',

	'Recency Bias':
		'The tendency to place greater importance on the most recent information or experiences when making decisions or judgments, often at the expense of earlier data or context.',

	'Negativity Bias':
		'The psychological tendency to give more attention and weight to negative experiences or information compared to positive or neutral ones, influencing perceptions and reactions.',

	'Social Proof':
		'The tendency to conform to the actions or beliefs of others, especially in situations of uncertainty, assuming that if many people are doing something, it must be correct.',

	Catastrophizing:
		'A cognitive distortion where a person assumes the worst possible outcome of a situation, often leading to heightened anxiety and avoidance behavior.',

	Personalization:
		"The tendency to attribute external events or others' actions as being directly related to oneself, often without sufficient evidence to support that interpretation.",

	Overgeneralization:
		'The cognitive distortion where a single negative event is seen as part of an endless pattern of defeat, leading to broad, overly negative conclusions.',

	'Mind Reading':
		'A cognitive distortion where one assumes they know what others are thinking, especially when assuming negative judgments or opinions without any real evidence.'
};
export const EMOTION_GROUP_COLOR_MAP = {
	Anger: '#F5A6A7',
	Anxiety: '#F9CAB1',
	Disgust: '#B3C7A9',
	Ennui: '#9EA3B8',
	Joy: '#F4E2B0',
	Sadness: '#AEC3E5',
	Surprise: '#E0CDEA'
};

export const get_dark_blue = () => {
	// return'#371A45'
	return '#2e404f';
	// return '#9B8EB6';
	// return '#2E2E2E';
	// return '#18153A';
	// return "#001F3F";
};

export const get_text_color = () => {
	return '#384252';
};

export const getSkyBlue = () => {
	return '#6EAAEB';
};
export const getDarkGray = () => {
	return '#DCDCDE';
};

export function get_light_blue() {
	return '#F2F5F9'; //F2F6FF'; //F2F5F9
}
export function getBlue() {
	return '#2F4150';
}
export function getSentiment(sentiment: string) {
	if (sentiment === undefined || sentiment === null) {
		return 'joy';
	}
	if (['positive', 'joy', 'neutral'].includes(sentiment.toLowerCase())) {
		return 'joy';
	} else if (['negative', 'harsh'].includes(sentiment.toLowerCase())) {
		return 'harsh';
	}
	return sentiment;
}

export const SentimentIcon = (props: {
	sentiment: string;
	size?: string;
	animated?: boolean;
	color?: string;
	marginTop?: string;
	marginLeft?: string;
}) => {
	if (props.sentiment === 'heart') {
		return (
			<FavoriteRounded
				sx={{
					width: props.size || '20px',
					marginLeft: props.marginLeft,
					height: props.size || '20px',
					marginRight: '8px',
					marginTop: props.marginTop,
					color: get_sentiment_color('harsh').color
				}}
			/>
		);
	}
	if (props.sentiment === 'sage_circle') {
		return (
			<img
				src={sageCircle}
				// style={{ width: "20px", height: "20px" }}
				width={props.size || '20px'}
				height={props.size || '20px'}
				style={{
					marginTop: props.marginTop,
					marginLeft: props.marginLeft
				}}
			/>
		);
	}
	if (props.sentiment === 'air') {
		return (
			<AirRounded
				sx={{
					width: props.size || '20px',
					marginLeft: props.marginLeft,
					height: props.size || '20px',
					color: props.color || getSkyBlue(),
					marginTop: props.marginTop
				}}
			/>
		);
	}
	if (props.sentiment === 'joy') {
		return (
			<WbSunnyRounded
				style={{
					color: props.color || get_sentiment_color('positive').color,
					width: props.size || '20px',
					marginLeft: props.marginLeft,
					height: props.size || '20px',
					marginTop: props.marginTop
				}}
			/>
		);
	} else if (props.sentiment === 'harsh') {
		if (props.animated) {
			return <MeteoconsLightningBoltFillAnimated />;
		}
		return (
			// <AcUnitRounded
			// 	sx={{
			// 		color: get_light_blue(),
			// 		width: props.size,
			// marginLeft: props.marginLeft,
			// 		height: props.size
			// 	}}
			// />
			// <WaterDropRounded
			// 	sx={{
			// 		color: get_light_blue(),
			// 		width: props.size,
			// marginLeft: props.marginLeft,
			// 		height: props.size
			// 	}}
			// />
			<FlashOnRounded
				style={{
					color: props.color || get_sentiment_color('harsh').color,
					width: props.size,
					marginLeft: props.marginLeft,
					height: props.size,
					marginTop: props.marginTop
				}}
			/>
		);
	} else if (props.sentiment.toLowerCase().includes('sage')) {
		return (
			// <img
			// 	src={sageCircle}
			// 	// style={{ width: "20px", height: "20px" }}
			// 	width={props.size || '20px'}
			// 	height={props.size || '20px'}
			// />
			// <Spa
			//   style={{
			//     color: get_sentiment_color("saged").color,
			//     width: props.size,
			//     height: props.size,
			//   }}
			// />
			// <img
			//   src={rocket}
			//   style={{ width: "20px", height: "20px" }}
			//   width={"20px"}
			//   height={"20px"}
			// />
			// <RocketLaunchRounded
			// 	style={{
			// 		color: props.color || get_cta_color(),
			// 		width: props.size,
			// 		height: props.size
			// 	}}
			// />
			// <FlashOnRounded
			// 	style={{
			// 		color: props.color || get_sentiment_color('sage').color,
			// 		width: props.size,
			// 		height: props.size
			// 	}}
			// />
			<StarRounded
				style={{
					color: props.color || get_sentiment_color('sage').color,
					width: props.size || '24px',
					marginLeft: props.marginLeft,
					height: props.size || '24px',
					marginTop: props.marginTop
				}}
			/>
		);
	} else if (props.sentiment === 'wisdom') {
		return (
			<img
				src={rocket}
				width="24px"
				style={{ marginLeft: props.marginLeft }}
			/>
		);
	} else if (props.sentiment == 'all thoughts') {
		return (
			<img
				// style={{ filter: 'blur(1px)' }}
				style={{ marginLeft: props.marginLeft }}
				src={cloud_gradient}
				width={props.size}
			/>
		);
	}
	return (
		<CloudRounded
			sx={{
				color: '#F2F0EF',
				// color: '#D0C2F1',
				width: props.size,
				height: props.size
			}}
		/>
	);
};

export function get_top_n_words_by_sentiment(
	thoughts: Thought[],
	sentiment: string,
	n?: number
) {
	const word_count: { [word: string]: number } = {};

	const exclude_set = new Set([
		'i',
		'was',
		'in',
		'of',
		'not',
		'like',
		'with',
		'at',
		'its',
		'just',
		'do',
		'me',
		'myself',
		'so',
		'it',
		'yo',
		'heya',
		'heya!',
		'and',
		'wee',
		'to',
		'the',
		'is',
		'my',
		'a',
		'that',
		'think',
		'but',
		"i'm",
		'for'
	]);

	// Loop through each object in the list
	thoughts.forEach((thought) => {
		// Split the text into words, using regex to handle punctuation and spaces
		const words = thought.thought.toLowerCase().split(/\s+/);

		// Count each word, excluding those in the exclude_set
		words.forEach((word) => {
			// Filter out empty strings and excluded words
			if (word && !exclude_set.has(word)) {
				word_count[word] = (word_count[word] || 0) + 1;
			}
		});
	});

	// Sort the words by their frequency in descending order and get the top 10
	const top_ten_words = Object.entries(word_count)
		.sort((a, b) => b[1] - a[1]) // Sort by frequency
		.slice(0, n || 10) // Get top 10 words
		.map((entry) => ({
			// Map to the new structure
			text: entry[0],
			value: entry[1],
			sentiment: sentiment
		}));

	return top_ten_words;
}
export type DateRange = 'Day' | 'Week' | 'Month';
export const EMOTION_DESCRIPTOR: { [key: string]: string } = {
	Admiration:
		'A feeling of respect and approval for someone or something, often driven by positive qualities or achievements.',

	Adoration:
		'Deep love and respect, typically mixed with awe or reverence for someone or something.',

	Altruism:
		'A selfless concern for the well-being of others, often manifesting in acts of kindness or generosity.',

	Amusement:
		'A light-hearted feeling of enjoyment or pleasure, usually triggered by something entertaining or funny.',

	Anger: 'A strong feeling of displeasure or hostility in response to perceived wrongs or injustices.',

	Anguish:
		'Severe emotional or mental pain, often stemming from intense suffering or distress.',

	Annoyance:
		'A mild form of irritation or frustration, typically caused by something persistent or bothersome.',

	Anticipation:
		'A feeling of excitement or anxiety about something that is going to happen, driven by expectations of future events.',

	Apathy: 'A lack of interest, enthusiasm, or concern, often resulting in emotional indifference or detachment.',

	Awe: 'A feeling of reverential respect mixed with fear or wonder, often inspired by something grand or powerful.',

	Bitterness:
		'A lingering feeling of resentment or anger, often caused by perceived injustice or betrayal.',

	Boredom:
		'A feeling of weariness and dissatisfaction arising from a lack of interest or excitement.',

	Calamity:
		'A feeling of extreme distress or concern, often in response to a catastrophic or disastrous event.',

	Calmness:
		'A peaceful, relaxed state of mind, free from agitation, excitement, or disturbance.',

	Confusion:
		'A state of being unsure or unclear about something, often accompanied by feelings of frustration or uncertainty.',

	Connection:
		'A sense of belonging or closeness to others, characterized by feelings of mutual understanding and support.',

	Contentment:
		"A state of happiness and satisfaction, often arising from acceptance of one's situation or life.",

	Craving:
		'A powerful desire for something, often related to physical needs or emotional longing.',

	Crushedness:
		'A deep feeling of emotional defeat or overwhelming sadness, often triggered by personal loss or failure.',

	Curiosity:
		'A desire to learn or know more about something, driven by an interest in exploration or discovery.',

	Distraction:
		'A feeling of being diverted or pulled away from focus, often leading to difficulty concentrating or staying on task.',

	Desire: 'A strong feeling of wanting to have or achieve something, often accompanied by motivation to pursue it.',

	Desperation:
		'A feeling of hopelessness or urgency, often leading to extreme actions or emotions in a difficult situation.',

	Determination:
		'A firm resolve or commitment to achieving a goal or overcoming obstacles, often fueled by inner strength.',

	Disappointment:
		'A feeling of sadness or displeasure when expectations or hopes are not met.',

	Disgust:
		'A strong feeling of aversion or repulsion, often in response to something offensive, distasteful, or morally wrong.',

	Dread: 'A feeling of fear or anxiety about something unpleasant that is likely to happen.',

	Ecstasy:
		'An overwhelming feeling of great happiness or joyful excitement, often intense and euphoric.',

	Embarrassment:
		'A feeling of awkwardness, shame, or discomfort, often triggered by a socially uncomfortable situation.',

	Envy: "A feeling of discontent or desire for someone else's possessions, qualities, or achievements.",

	Euphoria:
		'A feeling of intense happiness and excitement, often accompanied by a sense of invincibility or elation.',

	Excitement:
		'A state of heightened enthusiasm and eagerness, often in anticipation of something positive or thrilling.',

	Exhaustion:
		'A feeling of extreme physical or mental tiredness, often resulting from prolonged effort or stress.',

	Fear: 'A strong, unpleasant emotion caused by the belief that something dangerous or threatening is likely to happen.',

	Freedom:
		'A feeling of liberation or the absence of constraints, often associated with personal autonomy and independence.',

	Frustration:
		'A feeling of irritation and helplessness caused by obstacles preventing progress or success.',

	Gratitude:
		'A feeling of thankfulness and appreciation, often in response to kindness or positive outcomes.',

	Grief: 'A deep and intense sorrow, often resulting from the loss of a loved one or a significant life change.',

	Guilt: "A feeling of remorse or responsibility for something wrong or harmful, often linked to one's actions or decisions.",

	Hatred: 'An intense feeling of dislike or hostility, often directed toward someone or something perceived as harmful or offensive.',

	Heartache:
		'A deep emotional pain, often associated with loss, longing, or unrequited love.',

	Hope: 'An optimistic state of mind that expects positive outcomes or solutions to challenging situations.',

	Hostility:
		'A feeling of antagonism or opposition, often accompanied by aggressive or unfriendly behavior.',

	Humiliation:
		'A feeling of deep shame or embarrassment, often caused by being degraded or exposed to ridicule.',

	Hurt: 'A feeling of emotional pain or distress, often caused by rejection, betrayal, or disappointment.',

	Inadequacy:
		'A feeling of not being good enough or lacking the necessary qualities or abilities to succeed.',

	Indifference:
		'A lack of interest or concern, often leading to emotional detachment from situations or people.',

	Insecurity:
		"A feeling of uncertainty or anxiety about oneself, often resulting from doubts about one's abilities or worth.",

	Intrigue:
		'A feeling of curiosity and fascination, often sparked by something mysterious or novel.',

	Irritation:
		'A feeling of mild annoyance or discomfort, often caused by something persistent or trivial.',

	Jealousy:
		'A feeling of resentment or envy toward someone who has something one desires, often coupled with insecurity.',

	Joy: 'A feeling of great happiness and pleasure, often accompanied by a sense of well-being and contentment.',

	Listlessness:
		'A state of lacking energy or enthusiasm, often leading to an absence of motivation or interest.',

	Loneliness:
		'A feeling of sadness or emptiness resulting from being isolated or disconnected from others.',

	Longing:
		'A persistent desire or yearning for something, often accompanied by feelings of sadness or incompleteness.',

	Love: 'A deep and enduring emotional connection with someone or something, characterized by affection, care, and commitment.',

	Lust: 'A strong and intense desire, usually of a sexual nature, often driven by physical attraction or passion.',

	Melancholy:
		'A deep feeling of sadness or gloom, often accompanied by reflection on the past or a sense of longing.',

	Nervousness:
		'A feeling of unease or anxiety, often accompanied by physical symptoms like restlessness or a racing heart.',

	Nostalgia:
		'A sentimental longing for the past, often accompanied by bittersweet feelings of both joy and sorrow.',

	Panic: 'A sudden, overwhelming feeling of fear or anxiety, often leading to a loss of control or frantic behavior.',

	Passion:
		'A powerful emotion, often associated with intense enthusiasm or desire for something or someone.',

	Pity: "A feeling of sorrow and compassion for someone else's suffering or misfortune.",

	Pride: "A feeling of deep satisfaction and self-respect, often stemming from one's own achievements or those of close others.",

	Quiescence:
		'A state of quietness or inactivity, often accompanied by a sense of peace or stillness.',

	Rage: 'An intense and uncontrolled feeling of anger, often accompanied by aggressive or violent behavior.',

	Regret: 'A feeling of sorrow or disappointment over something that has happened or a missed opportunity.',

	Rejection:
		'A feeling of being dismissed or not accepted, often leading to emotional pain or insecurity.',

	Relief: 'A feeling of reassurance and relaxation following the removal of stress or uncertainty.',

	Remorse:
		'A deep feeling of regret or guilt for a wrong committed, often accompanied by a desire to make amends.',

	Resentment:
		'A feeling of bitterness or anger toward someone or something, often resulting from perceived unfair treatment.',

	Sadness:
		'A feeling of sorrow or unhappiness, often accompanied by a sense of loss or disappointment.',

	Sentimentality:
		'A tender emotional feeling, often nostalgic or overly sweet, sometimes to an exaggerated or unrealistic degree.',

	Serenity:
		'A state of calm and peacefulness, often associated with a sense of inner tranquility and balance.',

	Shame: 'A painful feeling of humiliation or distress caused by the awareness of wrong or foolish behavior.',

	Shock: 'A sudden and intense feeling of surprise or disbelief, often in response to an unexpected or disturbing event.',

	Shyness:
		'A feeling of discomfort or nervousness in social situations, often leading to a desire to avoid attention.',

	Sorrow: 'A deep and profound feeling of sadness or grief, often caused by loss or misfortune.',

	Stagnation:
		'A feeling of lack of progress or growth, often accompanied by frustration or dissatisfaction.',

	Stress: 'A feeling of emotional or mental strain, often caused by demanding situations or external pressures.',

	Surprise:
		'A sudden feeling of astonishment or wonder, often in response to something unexpected.',

	Terror: 'An extreme and overwhelming fear, often resulting in panic or a sense of helplessness.',

	Thrill: 'A feeling of excitement or exhilaration, often accompanied by a sense of heightened energy or anticipation.',

	Trepidation:
		'A feeling of fear or apprehension about something that may happen, often leading to hesitation.',

	Uncertainty:
		'A feeling of doubt or confusion, often arising from a lack of clear information or direction.',

	Unease: 'A feeling of discomfort or worry, often resulting from uncertainty or unfamiliarity with a situation.',

	Unhappiness:
		'A state of dissatisfaction or discontent, often characterized by feelings of sadness or discomfort.',

	Unrest: 'A feeling of disturbance or instability, often arising from unresolved tension or conflict.',

	Vengefulness:
		'A strong desire for revenge or retaliation, often fueled by feelings of anger or injustice.',

	Vulnerability:
		'A feeling of being exposed or open to emotional or physical harm, often leading to a sense of insecurity or fragility.',

	Worry: 'A feeling of anxiety or concern about potential problems or future events, often accompanied by restlessness.'
};

const EMOTION_GROUP_DATA = [
	{ value: 0, label: 'Anger', fill: '#E64A4D80' },
	{ value: 0, label: 'Anxiety', fill: '#F1956380' },
	{ value: 0, label: 'Joy', fill: '#E8C75D80' },
	{ value: 0, label: 'Disgust', fill: '#67905380' },
	{ value: 0, label: 'Sadness', fill: '#5B89CA80' },
	{ value: 0, label: 'Fear', fill: '#C19BD580' },
	{ value: 0, label: 'Ennui', fill: '#3E477180' }
];

export function get_emotion_group_data(thoughts: Thought[]) {
	const emotion_counts: { [emotion: string]: number } = {};

	// Initialize all counts to 0
	EMOTION_GROUP_DATA.forEach((item) => (emotion_counts[item.label] = 0));

	thoughts.forEach((thought) => {
		thought.user_selected_emotions.forEach((emotion: string) => {
			emotion_counts[get_emotion_group(emotion)]++;
		});
	});
	EMOTION_GROUP_DATA.forEach((item) => {
		item.value = emotion_counts[item.label] || 0;
	});

	return EMOTION_GROUP_DATA;
}

export const get_emotion_group = (emotion: string) => {
	if (emotion === undefined) {
		console.log('emotion is undefined');
		return 'Joy';
	}
	if (EMOTION_GROUP['Anger'].includes(emotion)) {
		return 'Anger';
	} else if (EMOTION_GROUP['Anxiety'].includes(emotion)) {
		return 'Anxiety';
	} else if (EMOTION_GROUP['Joy'].includes(emotion)) {
		return 'Joy';
	} else if (EMOTION_GROUP['Disgust'].includes(emotion)) {
		return 'Disgust';
	} else if (EMOTION_GROUP['Ennui'].includes(emotion)) {
		return 'Ennui';
	} else if (EMOTION_GROUP['Sadness'].includes(emotion)) {
		return 'Sadness';
	} else if (EMOTION_GROUP['Fear'].includes(emotion)) {
		return 'Fear';
	} else {
		console.log('Cannot find emotion group for ', emotion);
		return 'Joy';
	}
};
const EMOTION_GROUP: { [key: string]: string[] } = {
	Anger: [
		'Anger',
		'Annoyance',
		'Bitterness',
		'Frustration',
		'Hatred',
		'Hostility',
		'Irritation',
		'Jealousy',
		'Rage',
		'Resentment',
		'Vengefulness'
	],
	Anxiety: [
		'Confusion',
		'Desperation',
		'Dread',
		'Embarrassment',
		'Inadequacy',
		'Insecurity',
		'Nervousness',
		'Panic',
		'Shame',
		'Shock',
		'Stress',
		'Trepidation',
		'Uncertainty',
		'Unease',
		'Worry'
	],
	Joy: [
		'Admiration',
		'Adoration',
		'Altruism',
		'Amusement',
		'Anticipation',
		'Awe',
		'Calmness',
		'Connection',
		'Contentment',
		'Craving',
		'Curiosity',
		'Desire',
		'Determination',
		'Ecstasy',
		'Euphoria',
		'Excitement',
		'Freedom',
		'Gratitude',
		'Hope',
		'Joy',
		'Love',
		'Passion',
		'Pride',
		'Relief',
		'Serenity',
		'Surprise',
		'Thrill'
	],
	Disgust: [
		'Disgust',
		'Embarrassment',
		'Envy',
		'Guilt',
		'Humiliation',
		'Pity',
		'Regret',
		'Remorse'
	],
	Sadness: [
		'Anguish',
		'Boredom',
		'Calamity',
		'Crushedness',
		'Disappointment',
		'Grief',
		'Heartache',
		'Hurt',
		'Loneliness',
		'Melancholy',
		'Rejection',
		'Sadness',
		'Sorrow',
		'Unhappiness'
	],
	Fear: ['Fear', 'Dread', 'Panic', 'Shock', 'Terror', 'Vulnerability'],
	Ennui: [
		'Apathy',
		'Boredom',
		'Exhaustion',
		'Indifference',
		'Listlessness',
		'Quiescence',
		'Stagnation',
		'Unrest'
	]
};
export const COLORS = {
	wellbeing: {
		backgroundColor: '#EFECFA',
		color: '#D0C1F0',
		mid: '#DED2F3',
		text: '#897BB3',
		colorText: 'purple'
	}
};
export function getTimeDifference(timestamp: string) {
	const now = new Date();
	const time = new Date(timestamp);
	const diffInSeconds = Math.floor((now - time) / 1000);

	if (diffInSeconds < 60) {
		return `< 1 minute ago`;
	} else if (diffInSeconds < 3600) {
		const minutes = Math.floor(diffInSeconds / 60);
		return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
	} else {
		const hours = Math.floor(diffInSeconds / 3600);
		return `${hours} hour${hours === 1 ? '' : 's'} ago`;
	}
}

export function formatTime(timeString: string): string {
	const time = new Date(timeString);
	const isToday = (date: Date) => {
		const today = new Date();
		return (
			date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear()
		);
	};

	if (isToday(time)) {
		return time.toLocaleTimeString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true
		});
	}

	function formatDate(date: Date) {
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		const hours = date.getHours();
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const formattedHours = (hours % 12 || 12).toString();

		return `${month}/${day} ${formattedHours}:${minutes}${ampm}`;
	}
	return formatDate(time);
}

export const split_thoughts_by_sentiment = (thoughts: Thought[]) => {
	const joyful_thoughts = thoughts.filter(
		(thought) => thought.sentiment?.toLowerCase() === 'positive'
	);
	const harsh_thoughts = thoughts.filter(
		(thought) => thought.sentiment?.toLowerCase() === 'negative'
	);
	return {
		POSITIVE: joyful_thoughts,
		NEGATIVE: harsh_thoughts
	};
};
export function getMondayOfCurrentWeek() {
	const date = new Date();
	const day = date.getDay(); // Sunday - Saturday : 0 - 6
	//  Day of month - day of week (-6 if Sunday), otherwise +1
	const diff = date.getDate() - day + (day === 0 ? -6 : 1);
	return diff;
	// date.setDate(diff), date;
}
export const isSameDay = (date1String: string, date2String: string) => {
	const date1 = new Date(date1String);
	const date2 = new Date(date2String);
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
};
export function getTotalDaysInThisMonth(): number {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed

	const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();

	return lastDayOfMonth;
}

export function InsightsCard(props: {
	title: string;
	children: ReactNode;
	insightType: string;
	insights?: string[];
	marginTop?: string;
}) {
	return (
		<Grid
			container
			item
			xs
			marginTop={props.marginTop}
			// width={{ xl: '600px' }}
			// height="100%"
			direction="column"
			sx={{
				boxShadow: `0px 0px 2px 2px white`, // Apply shadow effect
				borderRadius: '16px',
				border: '1px solid #F3F5FA'
				// backgroundColor: '#F3F5FA'
			}}
		>
			{/* <Grid item xs={1} height="104px" padding="24px" direction="column">
				<SectionHeaderTypography title={props.title} />
				<Typography
					sx={{
						fontSize: '14px',
						lineHeight: '20px',
						fontWeight: 450,
						color: '#7F7D83'
					}}
				>
					{props.insightType}
				</Typography>
			</Grid>
			<Divider color="#F1F1F1" /> */}
			{/* <Grid container item xs>
				
			</Grid> */}
			{/* <SectionHeaderTypography title={props.title} /> */}
			{/* <Typography
				sx={{
					cursor: 'default',
					fontFamily: 'arial',
					fontSize: '16px',
					lineHeight: '24px',
					fontWeight: 500,
					color: '#0A090B',
					padding: '24px'
				}}
			>
				{props.title}
			</Typography> */}
			{props.children}
			{props.insights && (
				<Grid container item xs={1} padding="24px" paddingTop="0px">
					<KeyInsightsBox insights={props.insights} />
				</Grid>
			)}
		</Grid>
	);
}
export function getMondayToSunday() {
	const now = new Date();
	const currentDay = now.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

	// Calculate the difference between the current day and Monday
	const mondayDiff = currentDay - 1;
	const monday = new Date(now);
	monday.setDate(now.getDate() - mondayDiff);

	// Calculate the difference between Sunday and the current day
	const sundayDiff = 7 - currentDay;
	const sunday = new Date(now);
	sunday.setDate(now.getDate() + sundayDiff);

	// Check if Monday and Sunday are in the same month
	const isSameMonth = monday.getMonth() === sunday.getMonth();
	const isSameYear = monday.getFullYear() === sunday.getFullYear();

	// Format the result accordingly
	const formatter = new Intl.DateTimeFormat('en-US', {
		month: 'long'
	});
	let result;
	result = isSameMonth
		? ` ${formatter.format(
				sunday
		  )} ${monday.getDate()} - ${sunday.getDate()}`
		: `${formatter.format(monday)} ${monday.getDate()} ${
				!isSameYear ? monday.getFullYear() : ''
		  } - ${formatter.format(sunday)} ${sunday.getDate()} ${
				!isSameYear ? monday.getFullYear() : ''
		  }`;

	return result;
}

export function distributePercentage(items: number[]): number[] {
	const totalItems: number = items.reduce((acc, curr) => acc + curr, 0);
	const individualPercentages: number[] = items.map((item) =>
		Math.floor(100 * (item / totalItems))
	);
	const remainder: number =
		100 - individualPercentages.reduce((acc, curr) => acc + curr, 0);

	// Distribute the remainder
	for (let i = 0; i < remainder; i++) {
		const maxIndex: number = individualPercentages.indexOf(
			Math.max(...individualPercentages)
		);
		individualPercentages[maxIndex]++;
	}

	return individualPercentages;
}
export type JOY_ENUM = 'joy';
export function get_sentiment_color(type_input: string) {
	const type = type_input?.toLowerCase();
	if (type === null || type === undefined) {
		return {};
	}
	if (type === 'unknown' || type === 'undefined') {
		return {
			backgroundColor: '#F8F8F8',
			color: '#F8F8F8',
			mid: '#F8F8F8',
			textColor: '#F8F8F8',
			text: '#F8F8F8'
		}; // '#F8F8F8';
	}
	if (type === 'reframed' || type === 'saged' || type === 'sage') {
		return {
			backgroundColor: '#e2f1ea',
			color: '#A9D4C0',
			mid: '#FED7D8',
			textColor: '#F75656',
			text: '#69867A'
		};
	}
	if (['sad', 'Sad', 'Harsh', 'negative', 'harsh'].includes(type)) {
		return {
			backgroundColor: '#ffe2e1',
			// backgroundColor: get_light_blue(),
			color: '#FDA7A6',
			// color: getSkyBlue(),
			mid: '#FED7D8',
			textColor: '#F75656',
			text: '#FDA7A6'
		};
	}
	return {
		backgroundColor: '#ffefd2',
		// backgroundColor: '#FDDFA5',
		// color: '#FDDFA5',
		color: '#ffcc33',
		mid: '#CBE5D9',
		textColor: '#016626',
		text: '#DC47E0A'
	};
}
export const split_sage_thoughts_by_day_of_week = (
	sage_thoughts: SageThoughts
): SageThoughts[] => {
	// Initialize an array to hold data for each day of the week
	const weekData: SageThoughts[] = Array.from({ length: 7 }, () => ({})); // 0 = Monday, 6 = Sunday

	// Get the current date
	const today = new Date();

	// Find the start of the week (Monday)
	const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
	const daysSinceMonday = currentDay === 0 ? -6 : 1 - currentDay; // Adjust if today is Sunday
	const startOfWeek = new Date(today);
	startOfWeek.setDate(today.getDate() + daysSinceMonday);

	// Iterate over the items and categorize them by weekday
	Object.entries(sage_thoughts).forEach(([key, thought]) => {
		const created_at = new Date(thought.created_at);

		// Check if the item was created this week
		if (
			created_at >= startOfWeek &&
			created_at <
				new Date(startOfWeek).setDate(startOfWeek.getDate() + 7)
		) {
			const dayIndex = (created_at.getDay() + 6) % 7; // Adjust so that Monday = 0, ..., Sunday = 6
			weekData[dayIndex][key] = thought; // Add item to the corresponding day's object
		}
	});

	return weekData;
};

// Log the result
// const daysOfWeek = [
// 	'Monday',
// 	'Tuesday',
// 	'Wednesday',
// 	'Thursday',
// 	'Friday',
// 	'Saturday',
// 	'Sunday'
// ];
// weekItems.forEach((dayItems, index) => {
// 	console.log(`${daysOfWeek[index]}:`, dayItems);
// });

export function getMonth() {
	const now = new Date();
	// if (useString) {
	// 	return now.toLocaleString('default', { month: 'short' });
	// }
	const shortMonth = now.toLocaleString('default', { month: 'short' });
	return shortMonth;
}

export function getYear() {
	const now = new Date();
	return now.getFullYear();
}

export const WEEKDAY = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday'
];
export const WEEKDAY_ABBRV = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
export const WEEKDAY_ABBRV_SHORT = [
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat',
	'Sun'
];

export function get_cta_color(alpha?: number = 1) {
	return `rgb(155, 143, 182, ${alpha})`;
	// return '#2F4150';
	// return '#FF9500';
	// return '#F3AD68';
}

export function getCtaHoverColor() {
	return '#ACCBC2';
}

export function getDarkGreen() {
	return '#3B5148';
}

export function SubmitButton(props: {
	text: string;
	marginLeft?: string;
	marginBottom?: string;
	fullWidth?: boolean;
	onClick?: () => void;
}) {
	return (
		<Button
			fullWidth={props.fullWidth}
			onClick={() => props.onClick && props.onClick()}
			sx={{
				marginLeft: props.marginLeft,
				marginBottom: props.marginBottom,
				width: props.fullWidth ? undefined : '120px',
				height: props.fullWidth ? undefined : '40px',
				'&:hover': {
					backgroundColor: get_cta_color()
				},
				backgroundColor: get_cta_color(),
				textTransform: 'none'
			}}
		>
			<Typography
				sx={{
					fontSize: '14px',
					lineHeight: '20px',
					fontWeight: 450,
					color: 'white',
					letterSpacing: '-0.05px'
				}}
			>
				{props.text}
			</Typography>
		</Button>
	);
}

export const getTimestamp = () => {
	const now = new Date();
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, '0');
	const day = String(now.getDate()).padStart(2, '0');
	const hours = String(now.getHours()).padStart(2, '0');
	const minutes = String(now.getMinutes()).padStart(2, '0');
	const seconds = String(now.getSeconds()).padStart(2, '0');
	const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
	const timezoneOffset = now.getTimezoneOffset();
	const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60))
		.toString()
		.padStart(2, '0');
	const timezoneOffsetMinutes = (Math.abs(timezoneOffset) % 60)
		.toString()
		.padStart(2, '0');
	const timezoneSign = timezoneOffset >= 0 ? '-' : '+';

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;
};

export const get_sage_thought = (
	sage_thoughts: SageThoughts,
	thought_uuid: UUID
) => {
	return sage_thoughts[thought_uuid] || {};
};

export const parse_thoughts = (
	date_range: 'Day' | 'Week' | 'Month',
	thoughts: Thought[]
) => {
	if (date_range === 'Day') {
		return parse_daily_thoughts(thoughts);
	} else if (date_range === 'Week') {
		return parse_weekly_thoughts(thoughts);
	} else {
		return thoughts;
	}
};

export const parse_sage_thoughts = (
	date_range: 'Day' | 'Week' | 'Month',
	thoughts: SageThoughts
) => {
	if (date_range === 'Day') {
		return parse_daily_sage_thoughts(thoughts);
	} else if (date_range === 'Week') {
		return parse_weekly_sage_thoughts(thoughts);
	}
	return thoughts;
};

const parse_daily_sage_thoughts = (thoughts: SageThoughts): SageThoughts => {
	const today = new Date();
	today.setHours(0, 0, 0, 0); // Start of the day
	const endOfDay = new Date(today);
	endOfDay.setHours(23, 59, 59, 999); // End of the day

	return Object.fromEntries(
		Object.entries(thoughts).filter(([_, thought]) => {
			const created_at = new Date(thought.created_at);
			return created_at >= today && created_at <= endOfDay;
		})
	);
};

const parse_weekly_sage_thoughts = (thoughts: SageThoughts): SageThoughts => {
	const today = new Date();
	const firstDayOfWeek = new Date(today);
	const lastDayOfWeek = new Date(today);

	// Get the current day of the week (0-6 for Sunday-Saturday)
	const currentDay = today.getDay();

	// Calculate this week's Monday (0 = Sunday)
	const daysToMonday = currentDay === 0 ? -6 : 1 - currentDay; // Adjust if it's Sunday
	firstDayOfWeek.setDate(today.getDate() + daysToMonday);
	firstDayOfWeek.setHours(0, 0, 0, 0); // Start of Monday

	// Calculate this week's Sunday
	const daysToSunday = 7 - currentDay; // Adjust if it's Sunday
	lastDayOfWeek.setDate(today.getDate() + daysToSunday);
	lastDayOfWeek.setHours(23, 59, 59, 999); // End of Sunday

	return Object.fromEntries(
		Object.entries(thoughts).filter(([_, thought]) => {
			const created_at = new Date(thought.created_at);
			return created_at >= firstDayOfWeek && created_at <= lastDayOfWeek;
		})
	);
};

export const parse_daily_thoughts = (thoughts: Thought[]) => {
	const today = new Date();

	today.setHours(0, 0, 0, 0); // Set to start of day

	// return thoughts.filter((thought) => {
	// 	const thoughtDate = new Date(thought.created_at);
	// 	// Extract just the date part as "YYYY-MM-DD" and compare
	// 	const thoughtDateString = thoughtDate.toISOString().slice(0, 10);
	// 	const todayString = today.toISOString().slice(0, 10);

	// 	return thoughtDateString === todayString;
	// });
	return thoughts.filter((thought) => {
		const thought_date = new Date(thought.created_at);

		// Adjust thought_date to the local time zone by extracting year, month, and date
		const thought_date_local = new Date(
			thought_date.getFullYear(),
			thought_date.getMonth(),
			thought_date.getDate()
		);

		return thought_date_local.getTime() === today.getTime();
	});
};

export const parse_weekly_thoughts = (thoughts: Thought[]) => {
	// Get the current date
	const today = new Date();

	// Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
	const day_of_week = today.getDay();

	// Calculate the difference to get to the most recent Monday
	const monday_offset = (day_of_week === 0 ? -6 : 1) - day_of_week;
	const sunday_offset = 7 - day_of_week;

	// Get Monday and Sunday dates
	const monday = new Date(today);
	monday.setDate(today.getDate() + monday_offset); // Go to this week's Monday
	monday.setHours(0, 0, 0, 0); // Set the time to the start of the day

	const sunday = new Date(today);
	sunday.setDate(today.getDate() + sunday_offset); // Go to this week's Sunday
	sunday.setHours(23, 59, 59, 999); // Set the time to the end of the day

	// Filter the data
	const week_thoughts = thoughts.filter((thought) => {
		const createdAtDate = new Date(thought.created_at);
		return createdAtDate >= monday && createdAtDate <= sunday;
	});

	return week_thoughts;
};

export function split_thoughts_by_day_of_week(
	thoughts: Thought[]
): [Thought[][], Thought[][]] {
	// Create two arrays of 7 sub-arrays, one for positive and one for negative sentiment
	const joyful_days_of_week: Thought[][] = [[], [], [], [], [], [], []];
	const harsh_days_of_week: Thought[][] = [[], [], [], [], [], [], []];

	// Iterate through each sentiment object
	thoughts.forEach((thought: Thought) => {
		// Convert `created_at` to a Date object
		const date = new Date(thought.created_at);

		// Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
		const utcDay = date.getUTCDay();

		// Adjust so that Monday is index 0 and Sunday is index 6
		const dayIndex = utcDay === 0 ? 6 : utcDay - 1;

		// Based on the sentiment, add to the appropriate array
		if (thought.sentiment === 'positive') {
			joyful_days_of_week[dayIndex].push(thought);
		} else if (thought.sentiment === 'negative') {
			harsh_days_of_week[dayIndex].push(thought);
		}
	});

	return [joyful_days_of_week, harsh_days_of_week];
}

export const parse_monthly_thoughts = (thoughts: Thought[]) => {
	// Get the current date
	const now = new Date();

	//Fix ME

	// Calculate the start of the week (Monday)
	const start_of_week = new Date(now);
	start_of_week.setDate(now.getDate() - now.getDay() + 1);
	start_of_week.setHours(0, 0, 0, 0);

	// Calculate the end of the week (Sunday)
	const end_of_week = new Date(start_of_week);
	end_of_week.setDate(start_of_week.getDate() + 6);
	end_of_week.setHours(23, 59, 59, 999);

	// Filter the objects
	return thoughts.filter((thought) => {
		const created_date = new Date(thought.created_at);
		return created_date >= start_of_week && created_date <= end_of_week;
	});
};

export const onPostThought = async (message: string, dispatch: AppDispatch) => {
	dispatch(postThought(message));
};

export const wellbeingColor = {
	Spirit: {
		backgroundColor: '#EFECFA',
		color: '#D0C1F0',
		mid: '#DED2F3',
		text: '#897BB3',
		colorText: 'purple'
	},
	Mind: {
		backgroundColor: '#EFECFA',
		// color: '#A9D4C0',
		color: '#FDDFA5',
		mid: '#FDDFA5',
		text: '#5E9C88',
		colorText: 'red'
	},
	Body: {
		backgroundColor: '#EFECFA',
		color: '#FDA7A6',
		mid: '#FFB6D3',
		text: '#B86D6D',
		colorText: 'yellow'
	}
};

export const goldenHourColor = {
	Spirit: {
		backgroundColor: '#ECE7FF',
		color: '#C7BBF8',
		mid: '#C7BBF8',
		text: '#7C5CF3',
		colorText: 'purple'
	},
	Mind: {
		backgroundColor: '#D4E0FB',
		color: '#B8C9F3',
		mid: '#B8C9F3',
		text: '#446ACC',
		colorText: 'blue'
	},
	Body: {
		backgroundColor: '#FDEBF2',
		color: '#FFB6D3',
		mid: '#FFB6D3',
		text: '#F05F99',
		colorText: 'red'
	}
};

export function SaveButton() {
	return (
		<Button
			style={{
				backgroundColor: get_cta_color(),
				margin: '8px 0px',
				textTransform: 'none',
				borderRadius: '16px'
			}}
			onClick={() => {}}
		>
			<Typography
				sx={{ color: 'white', fontWeight: 600, fontSize: '12px' }}
			>
				Save
			</Typography>
		</Button>
	);
}
export function getGoldenHourColor(type: 'Spirit' | 'Mind' | 'Body') {
	return wellbeingColor[type];
}

export function getGoldenHourIcon(type: 'Mind' | 'Body' | 'Spirit') {
	return {
		Mind: LocalLibrary,
		Body: SportsGymnastics,
		Spirit: SelfImprovement
	}[type];
}

export function getWellbeingImage(
	type: 'Mind' | 'Body' | 'Spirit',
	isHovered: boolean
) {
	return {
		Mind: (
			<svg
				// align="center"
				// style={{ backgroundColor: "red" }}
				className={isHovered ? 'heartbeat' : ''}
				xmlns="http://www.w3.org/2000/svg"
				width="24px"
				height="24px"
				viewBox="0 0 18 18"
			>
				<path
					fill="#D0C2F1"
					fill-rule="evenodd"
					d="M1.633 2.796C2.395 1.959 3.483 1.5 4.76 1.5c1.164 0 2.407.55 3.24 1.625C8.827 2.05 10.065 1.5 11.24 1.5c1.274 0 2.36.457 3.124 1.292c.756.828 1.136 1.962 1.136 3.221c0 2.165-1.113 3.908-2.522 5.263c-1.405 1.352-3.17 2.383-4.633 3.14a.75.75 0 0 1-.693-.002c-1.463-.765-3.228-1.788-4.633-3.133C1.61 9.93.5 8.193.5 6.013c0-1.255.378-2.389 1.133-3.217m1.109 1.01C2.287 4.306 2 5.053 2 6.013c0 1.624.816 2.996 2.057 4.184c1.146 1.099 2.6 1.985 3.945 2.705c1.335-.71 2.79-1.604 3.937-2.707C13.182 8.999 14 7.62 14 6.013c0-.963-.288-1.71-.744-2.21C12.808 3.314 12.14 3 11.24 3c-.976 0-2.093.627-2.527 1.948a.75.75 0 0 1-1.426 0C6.854 3.628 5.725 3 4.76 3c-.903 0-1.57.314-2.018.806"
					clip-rule="evenodd"
				/>
			</svg>
			// <svg
			// 	className={isHovered ? "heartbeat" : ""}
			// 	xmlns="http://www.w3.org/2000/svg"
			// 	width="28"
			// 	height="28"
			// 	viewBox="0 0 24 24"
			// >
			// 	<path
			// 		fill="#D0C2F1"
			// 		d="M2 9.137C2 14 6.02 16.591 8.962 18.911C10 19.729 11 20.5 12 20.5s2-.77 3.038-1.59C17.981 16.592 22 14 22 9.138c0-4.863-5.5-8.312-10-3.636C7.5.825 2 4.274 2 9.137"
			// 	/>
			// </svg>
		),
		Body: isHovered ? (
			<img src={running} width="24px" height="24px" />
		) : (
			<img src={runningImg} width="24px" height="24px" />
		),
		// <MaterialSymbolsDirectionsRunRounded />,
		Spirit: isHovered ? (
			<img src={air} width="24px" height="24px" />
		) : (
			<MaterialSymbolsAirRounded />
		)
	}[type];
}

export const BreatheIcon = (props: { size?: string }) => {
	return (
		<AirRounded
			sx={{ width: props.size, height: props.size, color: getSkyBlue() }}
		/>
	);
};

export const GratitudeIcon = (props: { size?: string }) => {
	return (
		<FavoriteRounded
			sx={{
				width: props.size,
				height: props.size,
				color: get_sentiment_color('harsh').color
			}}
		/>
	);
};
export function getGoldenHourIndex(type: 'Spirit' | 'Mind' | 'Body') {
	return type === 'Spirit' ? 0 : type === 'Mind' ? 1 : 2;
}

export function KeyInsightsBox(props: { insights: string[] }) {
	return (
		<Grid
			container
			item
			xs
			direction="column"
			// justifyContent="flex-end"
			alignItems="flex-start"
			border="1px solid #ECECED"
			marginTop="24px"
			padding="24px"
			borderRadius="16px"
			// sx={{ backgroundColor: '#F6E5FE' }}
		>
			<Typography
				sx={{
					fontSize: '16px',
					lineHeight: '32px',
					fontWeight: 600,
					fontFamily: 'arial'
				}}
			>
				Key Insights
			</Typography>
			{props.insights.map((insight) => (
				<Typography
					sx={{
						fontSize: '14px',
						lineHeight: '32px',
						fontWeight: 500,
						fontFamily: 'arial'
					}}
				>
					<li>{insight}</li>
				</Typography>
			))}
		</Grid>
	);
}

export function filterTodaysThoughts(thoughts: Thought[]) {
	const today = new Date().toString();
	return thoughts.filter((obj) => {
		return isSameDay(obj.created_at, today);
	});
}

export function getTodayDay() {
	const today = new Date();
	return today.getDate();
}

export function getMonthYear() {
	const today = new Date();
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	const month = months[today.getMonth()];
	const year = today.getFullYear();
	return `${month}, ${year}`;
}
export function getDayOfWeek(date: Date): string {
	const daysOfWeek = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	];
	return daysOfWeek[date.getDay()];
}

export function getToday() {
	const date = new Date();
	return formatDate(date);
}

export function formatDate(date: Date): string {
	const daysOfWeek = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	];
	const dayOfWeek = daysOfWeek[date.getDay()];
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	const day = date.getDate();
	const month = date.getMonth() + 1;
	// months[date.getMonth()];
	const year = date.getFullYear() % 100;
	function getOrdinalSuffix(day: number) {
		if (day >= 11 && day <= 13) {
			return `${day}th`;
		} else {
			const lastDigit = day % 10;
			switch (lastDigit) {
				case 1:
					return `${day}st`;
				case 2:
					return `${day}nd`;
				case 3:
					return `${day}rd`;
				default:
					return `${day}th`;
			}
		}
	}
	const formattedDate = `${month}/${day}/${year} ${getOrdinalSuffix(day)}`;
	// const formattedDate = `${month}/${day}/${year}`;
	// return formattedDate;

	return date.toLocaleDateString('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric'
	});
}

export function getCurrentTime() {
	const currentTime = new Date();
	return currentTime.toLocaleTimeString([], {
		hour: 'numeric',
		minute: '2-digit',
		hour12: true
	});
}

export const getUtcTimestamp = () => {
	const utcTimestamp = new Date().toISOString();
	return format(new Date(utcTimestamp), 'yyyy-MM-dd HH:mm:ss XXX', {
		timeZone: 'UTC'
	});
};

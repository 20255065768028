import { Grid, Typography } from '@mui/material';
import {
	InsightsCard,
	WEEKDAY_ABBRV_SHORT,
	get_cta_color,
	parse_thoughts
} from '../../../../Util/Helper';
import { RootState, Thought } from '../../../../Util/Redux/Schema';

import StatRatioMeter from './StatRatioMeter';
import { useSelector } from 'react-redux';

export default function WeeklySummary() {
	return (
		<Grid
			container
			item
			justifyContent="center"
			sx={{
				height: '100%',
				borderRadius: '12px',
				border: '1px solid #F3F5FA',
				padding: '8px'
			}}
		>
			<Grid container item xs>
				<InsightsCard insightType="Thoughts" title="Emotions">
					<Grid
						container
						item
						xs
						// direction="column"
						justifyContent="center"
						alignItems="center"
						sx={{ backgroundColor: 'white' }}
						padding="24px"
					>
						<WeeklySentimentBar />
					</Grid>
				</InsightsCard>
			</Grid>
		</Grid>
	);
}

export const WeeklySentimentBar = () => {
	const thoughts = useSelector(
		(state: RootState) => state.thoughts.current_month_thoughts
	);
	const this_week_thoughts = parse_thoughts('Week', thoughts);

	const checkFunction = (utcString: string): number => {
		const date = new Date(utcString);
		const dayOfWeek = date.getUTCDay();
		return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
	};

	const week: [
		Thought[],
		Thought[],
		Thought[],
		Thought[],
		Thought[],
		Thought[],
		Thought[]
	] = this_week_thoughts.reduce(
		(acc, thought) => {
			const groupIndex = checkFunction(thought.created_at);
			acc[groupIndex].push(thought);
			return acc;
		},
		[[], [], [], [], [], [], []] as [
			Thought[],
			Thought[],
			Thought[],
			Thought[],
			Thought[],
			Thought[],
			Thought[]
		]
	);

	// No sentiment = Joy
	const getJoyfulThoughts = (thoughts: Thought[]) =>
		thoughts.filter(
			(thought) =>
				thought.user_selected_emotions.length === 0 ||
				thought.sentiment?.toLowerCase() === 'positive'
		);
	const getHarshThoughts = (thoughts: Thought[]) =>
		thoughts.filter(
			(thought) => thought.sentiment?.toLowerCase() === 'negative'
		);

	const getSortedSentimentData = (thoughts: Thought[]) => {
		const joy = getJoyfulThoughts(thoughts).length;
		const harsh = getHarshThoughts(thoughts).length;
		const priority = {
			joy: 1,
			harsh: 2
		};
		return [
			['joy', joy],
			['harsh', harsh]
		].sort((a, b) => {
			// First, sort by number (in descending order)
			if (b[1] !== a[1]) {
				return b[1] - a[1];
			}
			// If numbers are the same, sort by priority
			return priority[a[0]] - priority[b[0]];
		});
	};
	return (
		<>
			<>
				<Grid container item xs={1}>
					<Grid container item xs alignItems="center">
						<Typography
							sx={{
								color: 'white',
								fontFamily: 'arial',
								fontSize: '16px',
								fontWeight: 600
							}}
						>
							Weekly Vibe Check
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					item
					xs
					direction="column"
					alignItems="center"
					// padding="0 8px"
				>
					{WEEKDAY_ABBRV_SHORT.map((day, idx) => {
						const sortedSentimentData = getSortedSentimentData(
							week[idx]
						);
						return (
							<>
								<StatRatioMeter
									darkmode
									total={week[idx].length}
									sorted_sentiment_data={sortedSentimentData}
									text={day}
									size="small"
									bottom_border={
										idx !== WEEKDAY_ABBRV_SHORT.length - 1
									}
								/>
							</>
						);
					})}
				</Grid>
			</>
		</>
	);
};

import {
	Bar,
	ComposedChart,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import { get_cta_color, get_sentiment_color } from '../../../../Util/Helper';

import { BarChart } from '@mui/x-charts';
import { Grid } from '@mui/material';

export default function BreatheCountBarChart(props: {
	date_range: 'Day' | 'Week' | 'Month';
	legend?: any;
}) {
	const { date_range } = props;
	const breaths = [5, 6, 7, 8, 9, 10, 11];
	const data_series = [
		{
			type: 'bar',
			data: breaths,
			stack: 'Positive',
			id: 'Positive',
			label: 'Joy',
			color: 'white',
			width: '1px'
		}
	];
	return (
		<Grid
			container
			item
			direction="column"
			xs
			sx={{
				// backgroundColor: 'white',
				borderRadius: '8px'
				// height: "180px",
				// padding: '24px'
			}}
		>
			<Grid
				container
				item
				direction="column"
				sx={{
					// backgroundColor: 'white',
					borderRadius: '8px',
					height: '240px'
				}}
			>
				<Grid container item xs={1} marginBottom="-32px">
					{props.legend}
				</Grid>
				<Grid container item xs>
					<LineAndBarChart data={data_series} />
				</Grid>
			</Grid>
		</Grid>
	);
}

export function LineAndBarChart(props: { bar_data: any; line_data: any }) {
	const data = [
		{
			name: 'Mon',
			joy_ratio: 0.6,
			breaths: 1
		},
		{
			name: 'Tue',
			breaths: 2,
			joy_ratio: 1
		},
		{
			name: 'Wed',
			joy_ratio: 0.6,
			breaths: 3
		},
		{
			name: 'Thu',
			joy_ratio: 1.6,
			breaths: 0
		},
		{
			name: 'Fri',
			joy_ratio: 2.1,
			breaths: 4
		},
		{
			name: 'Sat',
			joy_ratio: 0.6,
			breaths: 5
		},
		{
			name: 'Sun',
			joy_ratio: 0.6,
			breaths: 5
		}
	];

	return (
		<ResponsiveContainer width="100%" height="100%">
			<ComposedChart
				// width={500}
				// height={400}
				data={data}
				margin={{
					top: 20,
					right: 20,
					bottom: 20
					// left: 20
				}}
			>
				{/* <CartesianGrid stroke="#f5f5f5" /> */}
				<XAxis
					tickLine={false}
					dataKey="name"
					stroke="white"
					fontFamily="arial"
					fontSize="12px"
				/>
				<YAxis
					tickLine={false}
					stroke="white"
					fontFamily="arial"
					fontSize="12px"
				/>
				<Tooltip />
				{/* <Legend /> */}

				<Bar
					dataKey="breaths"
					barSize={20}
					fill={get_cta_color()}
					radius={[4, 4, 0, 0]}
				/>

				<Line
					dot={false}
					dataKey="joy_ratio"
					stroke={get_sentiment_color('joy').color}
				/>
				{/* <Scatter dataKey="cnt" fill="red" /> */}
			</ComposedChart>
		</ResponsiveContainer>
	);
}

export function _Chart(props: { data: any }) {
	const { data } = props;
	const week = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

	return (
		<BarChart
			legend={{ hidden: true }}
			series={data}
			height={240}
			// leftAxis={{
			// 	label: null // or label: ''
			// }}
			xAxis={[
				{
					scaleType: 'band',
					data: week,
					categoryGapRatio: 0.8,
					tickLabelStyle: {
						fill: 'white' // Change x-axis label color to blue
					}
					// barGapRatio: 0.1
				}
			]}
			hideLegend={true}
			// withHorizontalLabels={false}
			sx={{
				'& .MuiChartsAxis-tickLabel tspan': {
					fontSize: '12px',
					fill: '#7F7D83'
				},
				'& .MuiChartsAxis-left .MuiChartAxis-tickLabel tspan': {
					// stroke: '#7F7D83',
					fill: 'white'
				},
				'& .MuiChartsAxis-left .MuiChartsAxis-line': {
					stroke: '#7F7D83',
					strokeWidth: 0
				},
				'& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
					stroke: '#7F7D83',
					strokeWidth: 0
				},
				'& .MuiChartsAxis-tick': {
					stroke: '#7F7D83',
					strokeWidth: 0
				}
			}}
			slotProps={{
				// bar: (props) => <CustomBarComponent {...props} />
				bar: (props) => {
					return {
						clipPath: `inset(0px round 15px 15px 15px 15px)`
					};
				}
			}}
		/>
	);
}

import { Grid, Typography } from '@mui/material';

import BreatheCountBarChart from './BreatheCountBarChart';
import { get_cta_color } from '../../../../Util/Helper';

export default function BreathReport(props: {
	date_range: 'Day' | 'Week' | 'Month';
	sentiment: string;
}) {
	return (
		<>
			<Grid container item xs={1}>
				<Grid container item xs direction="column">
					<Typography
						sx={{
							color: 'white',
							fontFamily: 'arial',
							fontSize: '16px',
							fontWeight: 600
						}}
					>
						Breaths
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs
				justifyContent="center"
				alignItems="center"
				marginTop="8px"
				// sx={{ height: '420px' }}
			>
				<BreatheCountBarChart date_range="Week" />
			</Grid>
		</>
	);
}

import { Grid, Typography } from '@mui/material';

import { FavoriteRounded } from '@mui/icons-material';
import { RootState } from '../../../Util/Redux/Schema';
import { get_sentiment_color } from '../../../Util/Helper';
import { useSelector } from 'react-redux';

export default function GratitudesReport(props: {
	date_range: 'Day' | 'Week' | 'Month';
	sentiment: string;
}) {
	const gratitudes = useSelector(
		(state: RootState) => state.pulseOfPlanet.gratitudes
	);
	return (
		<>
			<Grid container item xs={1}>
				<Grid container item direction="column" justifyContent="center">
					<Grid container item xs>
						<Grid container item direction="column" xs={8}>
							<Typography
								sx={{
									color: 'white',
									fontFamily: 'arial',
									fontSize: '16px',
									fontWeight: 600
								}}
							>
								Gratitudes
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs
				sx={{
					overflowY: 'auto',
					borderRadius: '8px',
					padding: '8px'
				}}
			>
				<Grid container xs item direction="column">
					{gratitudes.map((gratitude) => (
						<Grid container item marginBottom="8px">
							<Grid container item xs={1}>
								<FavoriteRounded
									sx={{
										color: get_sentiment_color('harsh')
											.color,
										width: '16px',
										height: '16px',
										marginTop: '4px',
										marginRight: '8px'
									}}
								/>
							</Grid>
							<Grid container item xs>
								<Typography
									sx={{
										// color: '#2f2f2f',
										color: 'white',
										fontSize: '16px',
										fontFamily: 'arial'
									}}
								>
									{gratitude}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
		</>
	);
}

import { createTheme } from '@mui/material/styles';

// Create your custom theme
const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#656E61'
		},
		secondary: {
			main: '#CCE6E2'
		},
		action: {
			disabled: 'white'
		}
	},
	typography: {
		fontFamily: 'Roboto, sans-serif',
		fontSize: 14
		// Customize other typography styles here
	},
	components: {
		MuiButton: {
			styleOverrides: {
				disabled: {
					backgroundColor: 'white' // Replace 'your_color_here' with the desired color value
				}
			}
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920
		}
	}
	// Customize other theme options here
});

export default theme;

import { Grid, TextareaAutosize } from '@mui/material';
import React, { useRef, useState } from 'react';

import { AppDispatch } from '../../Util/Redux/Schema';
import CustomTextField from '../../components/molecules/CustomTextField';
import { add_gratitude } from '../../Util/Redux/Middleware';
import { post_gratitude } from '../../Util/Redux/AsyncMiddleware';
import { useDispatch } from 'react-redux';

const GratitudeBar = () => {
	const [message, setMessage] = useState(
		localStorage.getItem('gratitudeBarMessage') || ''
	);
	const dispatch = useDispatch<AppDispatch>();
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(event.target.value);
		localStorage.setItem('gratitudeBarMessage', event.target.value);
	};
	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault(); // Prevent Line Break
			dispatch(post_gratitude(message));
			setMessage('');
			localStorage.removeItem('gratitudeBarMessage');
		} else if (event.key === 'Enter' && event.shiftKey) {
			event.preventDefault();
			setMessage(message + '\n'); // Add a newline character to the message
			localStorage.setItem('gratitudeBarMessage', message + '\n');
		}
	};

	return (
		<Grid
			container
			item
			justifyContent="space-between"
			width="100%"
			alignItems="center"
			sx={{
				backgroundColor: '#F8F8F8',
				borderRadius: '32px',
				padding: '8px 16px'
			}}
		>
			<Grid container item xs>
				{/* <CustomTextField
					placeholder="I am grateful for..."
					value={message}
					setValue={setMessage}
					backgroundColor="#F8F8F8"
					onChange={handleChange}
					onKeyPress={handleKeyPress}
				/> */}
				<TextareaAutosize
					placeholder="I am grateful for..."
					value={message}
					maxRows={2}
					style={{
						fontFamily: 'arial',
						color: '#0A090B',
						fontWeight: 450,
						backgroundColor: '#F8F8F8',
						fontSize: '14px',
						lineHeight: '20px',
						letterSpacing: '-0.05px',
						width: '100%',
						border: 'none',
						outline: 'none',
						resize: 'none',
						minHeight: '32px',
						paddingTop: '12px'
					}}
					onChange={handleChange}
					onKeyPress={handleKeyPress}
					autoFocus
				/>
			</Grid>
		</Grid>
	);
};

export default GratitudeBar;

import '../App.css';

import {
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
	useNavigate
} from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { Theme, useMediaQuery } from '@mui/material';
// import Login from './Login2';
import store, { persistor } from '../Util/Redux/Store';
import { useEffect, useState } from 'react';

import { AppDispatch } from '../Util/Redux/Schema';
import CallbackPage from '../components/pages/CallbackRoute';
import Dashboard from '../components/pages/Dashboard';
import ErrorPage from '../components/pages/ErrorPage';
import LandingPage from '../components/pages/LandingPage';
import LockScreen from '../components/pages/LockScreenPage';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import SizeErrorPage from '../components/pages/SizeErrorPage';
import { ThemeProvider } from '@mui/styles';
import Waitlist from '../components/pages/WaitlistPage';
import axios from 'axios';
import { fetchUser } from '../Util/Redux/Middleware';
import { setBearerTokenToAxios } from '../Util/Redux/AsyncMiddleware';
import { setupAxiosInterceptors } from '../Util/axiosConfig';
import theme from '../Util/Theme';
import { useAuth0 } from '@auth0/auth0-react';

// import { setBearerToken } from '../Util/Redux/AsyncMiddleware';

const AppRoutes = () => {
	// const is_mobile = false;
	const is_mobile = useMediaQuery('(max-width:1366px)');
	const dispatch = useDispatch<AppDispatch>();
	const { getAccessTokenSilently, isAuthenticated, user, isLoading, logout } =
		useAuth0();
	if (sessionStorage.getItem('isAuthenticated') !== 'True') {
		sessionStorage.setItem(
			'isAuthenticated',
			isAuthenticated ? 'True' : 'False'
		);
	}
	useEffect(() => {
		const handleAuthenticatedUser = async () => {
			if (sessionStorage.getItem('bearerToken')) {
				setBearerTokenToAxios();
			}
			{
				try {
					if (isAuthenticated && user) {
						dispatch(fetchUser({ firstName: user.given_name }));
						sessionStorage.setItem(
							'username',
							user.given_name || ''
						);
						sessionStorage.setItem('userPic', user.picture || '');
						sessionStorage.setItem('isAuthenticated', 'True');
						const token = await getAccessTokenSilently();
						sessionStorage.setItem('bearerToken', token);
						setBearerTokenToAxios();
					}
				} catch (error) {
					console.error('Error handling authenticated user:', error);
				}
			}
		};
		handleAuthenticatedUser();
	}, [isAuthenticated, user, getAccessTokenSilently, dispatch]);

	if (isLoading && sessionStorage.getItem('username') === null) {
		return <div>Loading...</div>;
	}
	const is_valid_user =
		sessionStorage.getItem('authorized_state') !== 'user_not_found';

	return (
		<Router>
			<Routes>
				<Route path="/error" element={<ErrorPage />} />
				<Route path="/auth/callback" element={<CallbackPage />} />
				{is_mobile ? (
					<>
						<Route path="/" element={<SizeErrorPage />} />
						<Route path="/waitlist" element={<SizeErrorPage />} />
						<Route
							path="/space"
							element={
								is_valid_user ? (
									<SizeErrorPage />
								) : (
									<Navigate to="/" />
								)
							}
						/>
						<Route
							path="/space/gringotts"
							element={
								is_valid_user ? (
									<SizeErrorPage />
								) : (
									<Navigate to="/waitlist" />
								)
							}
						/>
					</>
				) : (
					<>
						<Route path="/" element={<LandingPage />} />
						<Route path="/waitlist" element={<Waitlist />} />
						<Route
							path="/space"
							element={
								is_valid_user ? (
									<Dashboard />
								) : (
									<Navigate to="/" />
								)
							}
						/>
						<Route
							path="/space/gringotts"
							element={
								is_valid_user ? (
									<LockScreen />
								) : (
									<Navigate to="/waitlist" />
								)
							}
						/>
					</>
				)}

				{/* <Route
					path="/space/settings"
					element={
						sessionStorage.getItem('isAuthenticated') === 'True' &&
						sessionStorage.getItem('IsPinAuthorized') ? (
							<AccountSettingsPage tab="settings" />
						) : (
							<Navigate
								to={
									sessionStorage.getItem(
										'isAuthenticated'
									) === 'True' || isAuthenticated
										? '/space/gringotts'
										: '/'
								}
							/>
						)
					}
				/> */}
			</Routes>
		</Router>
	);
};

export default function App() {
	const loc = window.location;
	const baseUrl = `${loc.protocol}//${loc.hostname}${
		loc.hostname === 'localhost' ? ':8080' : ''
	}`;
	axios.defaults.baseURL = baseUrl;
	useEffect(() => {
		setupAxiosInterceptors();
	}, []);
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<ThemeProvider theme={theme}>
					<AppRoutes />
				</ThemeProvider>
			</PersistGate>
		</Provider>
	);
}

import { Grid } from '@mui/material';
import ThoughtsEditor from '../../quest/thought/feed/ThoughtEditor';
import Wellness from '../molecules/HomeRHS';

export const Home = () => {
	return (
		<Grid
			container
			item
			width="1024px"
			height="768px"
			maxHeight="100%"
			direction="column"
			justifyContent="center"
		>
			<Grid container item xs width="100%">
				<ThoughtsEditor />
				<Grid
					container
					direction="column"
					item
					xs
					marginLeft="8px"
					borderRadius="16px"
				>
					<Wellness showStatsView={false} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Home;

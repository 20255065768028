import { Grid, Modal } from '@mui/material';
import React, { useState } from 'react';

import Quest from '../components/organisms/QuestMain';
import skyBg from '../assets/images/svg-files/skyBg.svg';

export default function QuestModal(props: {
	open: boolean;
	setOpen: (open: boolean) => void;
}) {
	return (
		<Modal
			open={props.open}
			onClose={() => props.setOpen(false)}
			sx={{ zIndex: 2000, backdropFilter: 'blur(5px)' }}
		>
			<Grid
				container
				sx={{
					backgroundImage: `url(${skyBg})`, // Add background image if needed
					backgroundSize: 'cover',
					height: '800px',
					width: '1200px'
					// height: '768px',
					// width: '1024px'
				}}
				style={{
					borderRadius: '16px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					overflowX: 'hidden',
					overflowY: 'hidden'
				}}
				direction="column"
			>
				<Quest />
			</Grid>
		</Modal>
	);
}

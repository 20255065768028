import { Grid, Typography } from '@mui/material';
import { SentimentIcon, get_cta_color } from '../../Util/Helper';

import { AirRounded } from '@mui/icons-material';
import BreathCircle from './BreathCircle';
import { RootState } from '../../Util/Redux/Schema';
import { useSelector } from 'react-redux';

export default function Breathwork() {
	const breaths = useSelector(
		(state: RootState) => state.pulseOfPlanet.breath_count
	);
	return (
		<Grid
			container
			item
			xs
			// marginTop="16px"
			direction="column"
		>
			<Grid container item xs={1}>
				<SentimentIcon sentiment="air" size="16px" />
				{/* <AirRounded
					sx={{
						width: '16px',
						height: '16px',
						marginRight: '8px',
						color: get_cta_color()
					}}
				/> */}
				{/* <Typography
					sx={{
						color: '#2f2f2f',
						fontSize: '12px',
						textAlign: 'center',
						fontFamily: 'arial'
					}}
				>
					Breathe
				</Typography> */}
				<Typography
					sx={{
						color: '#2f2f2f',
						fontSize: '12px',
						fontFamily: 'arial',
						marginLeft: '8px'
					}}
				>
					Breaths: {breaths || 0}
				</Typography>
			</Grid>

			<Grid
				container
				item
				xs
				sx={{
					// backgroundColor: '#F2F5F9',
					borderRadius: '8px',
					marginTop: '8px'
				}}
			>
				<BreathCircle
					initText="Longest session: 8 breaths"
					size="small"
					maxCount={500}
				/>
			</Grid>
		</Grid>
	);
}

import * as React from 'react';

import {
	ArrowDropDown,
	LockRounded,
	LogoutRounded,
	RocketRounded
} from '@mui/icons-material';
import {
	Avatar,
	Divider,
	Grid,
	IconButton,
	Slide,
	Tooltip
} from '@mui/material';
import { setAuthenticated, setAuthorized } from '../../Util/Redux/Middleware';
import { useDispatch, useSelector } from 'react-redux';

import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import QuestModal from '../../quest/QuestModal';
import { RootState } from '../../Util/Redux/Schema';
import Typography from '@mui/material/Typography';
import { clearPersistedState } from '../../Util/Redux/Store';
import fontQuest from '../../assets/images/fontQuest.png';
import fontSage from '../../assets/images/fontSage.png';
import fontSpace from '../../assets/images/fontSpace.png';
import fontVault from '../../assets/images/fontVault.png';
import { headerHeight } from '../../Util/Config';
import helpCenterIcon from '../../assets/images/svg-files/helpCenter.svg';
import logoutIcon from '../../assets/images/svg-files/logout.svg';
import settingsIcon from '../../assets/images/svg-files/settings.svg';
import subscriptionsIcon from '../../assets/images/svg-files/subscriptions.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export interface ToolbarOptionDefinition {
	text: ToolbarOption;
	icon: React.ReactElement;
}

export type ToolbarOption = 'Meditate' | 'Frequency Sound' | 'Lock';

const Bar = (props: { title: string }) => {
	const navigate = useNavigate();

	const onLock = () => {
		sessionStorage.removeItem('isPinAuthorized');
		setAnimateLock(true);
		navigate('/space/gringotts');
	};
	const { logout } = useAuth0();
	const userPic = sessionStorage.getItem('userPic');
	const dispatch = useDispatch();
	const onLogout = () => {
		dispatch(setAuthorized(false));
		dispatch(setAuthenticated(null));
		clearPersistedState();
		sessionStorage.clear();
		logout({
			logoutParams: {
				returnTo: window.location.origin
			}
		});
	};
	const [animateLock, setAnimateLock] = React.useState(false);
	const [openQuest, setOpenQuest] = React.useState(false);
	const onShowQuest = () => {
		setOpenQuest(true);
	};
	return (
		<Grid container item width="320px" justifyContent="flex-end">
			<QuestModal open={openQuest} setOpen={setOpenQuest} />
			<Tooltip title="Open Sage Report">
				<IconButton
					sx={{
						marginRight: '16px',
						color: '#AEB2BC',
						width: '46px',
						height: '46px'
					}}
					onClick={onShowQuest}
				>
					{/* <img
											src={rocket}
											style={{ width: '20px' }}
										/> */}
					<RocketRounded
						style={
							{
								// color: 'lightgray',
								// transform: 'rotate(45deg)'
							}
						}
					/>
				</IconButton>
			</Tooltip>

			<Tooltip title="Leave Sage Quest">
				<IconButton
					sx={{
						marginRight: '16px',
						color: '#AEB2BC',
						width: '46px',
						height: '46px'
					}}
					onMouseEnter={() => setAnimateLock(true)}
					onMouseLeave={() => setAnimateLock(false)}
					onClick={onLogout}
				>
					<LogoutRounded />
				</IconButton>
			</Tooltip>
			<Tooltip title="Lock screen">
				<IconButton
					sx={{
						marginRight: '16px',
						color: '#AEB2BC',
						width: '46px',
						height: '46px'
					}}
					onMouseEnter={() => setAnimateLock(true)}
					onMouseLeave={() => setAnimateLock(false)}
					onClick={onLock}
				>
					<LockRounded />
				</IconButton>
			</Tooltip>
		</Grid>
	);
};

export function AccountMenu() {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const name = useSelector((selector: RootState) => selector.user.firstName);
	const dispatch = useDispatch();
	const { logout } = useAuth0();
	const userPic = sessionStorage.getItem('userPic');
	const signout = () => {
		dispatch(setAuthorized(false));
		dispatch(setAuthenticated(null));
		clearPersistedState();
		sessionStorage.clear();
		logout({
			logoutParams: {
				returnTo: window.location.origin
			}
		});
	};
	return (
		<>
			<Tooltip title="Account">
				<Grid
					container
					item
					xs
					alignItems="center"
					onClick={handleClick}
					sx={{ '&:hover': { cursor: 'pointer' } }}
				>
					<IconButton
						sx={{
							width: '40px',
							height: '40px',
							backgroundColor: '#E4F2Ec'
						}}
						disableRipple
					>
						<a href={userPic} target="_blank" rel="noreferrer">
							<Avatar
								sx={{
									color: '#74A899',
									bgcolor: '#E4F2EC',
									border: '1px solid #F8F8F8',
									width: '40px',
									height: '40px'
								}}
								alt="Uu Profile Picture"
								src={userPic}
							/>
						</a>
					</IconButton>

					<ArrowDropDown
						sx={{ color: '#AEB2BC', marginLeft: '4px' }}
					/>
				</Grid>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 1,
					sx: {
						width: '180px',
						borderRadius: '8px',
						filter: 'drop-shadow(0px 2px 8px #0A090B12)',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1
						},
						'&::before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0
						}
					}
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem onClick={() => navigate('/settings')}>
					<ListItemIcon>
						<img src={settingsIcon} />
					</ListItemIcon>

					<Typography
						sx={{
							color: '#7F7D83',
							fontWeight: 450,
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '-0.5px'
						}}
					>
						Settings
					</Typography>
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<img src={subscriptionsIcon} />
					</ListItemIcon>

					<Typography
						sx={{
							color: '#7F7D83',
							fontWeight: 450,
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '-0.5px'
						}}
					>
						Subscriptions
					</Typography>
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<img src={helpCenterIcon} />
					</ListItemIcon>

					<Typography
						sx={{
							color: '#7F7D83',
							fontWeight: 450,
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '-0.5px'
						}}
					>
						Help Center
					</Typography>
				</MenuItem>
				<Divider />
				<MenuItem onClick={signout}>
					<ListItemIcon>
						<img src={logoutIcon} />
					</ListItemIcon>
					<Typography
						sx={{
							color: '#F75656',
							fontWeight: 450,
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '-0.5px'
						}}
					>
						Logout
					</Typography>
				</MenuItem>
			</Menu>
		</>
	);
}

export default function Header(props: { page: string }) {
	const title = props.page === 'Home' ? `homepage` : props.page.toLowerCase();
	const titleImage = {
		space: fontSpace,
		ether: fontSpace,
		lock: fontVault
	}[title];
	return (
		<Grid
			container
			item
			xs
			alignItems="center"
			sx={{
				position: 'fixed',
				height: headerHeight,
				top: 0,
				border: '1px solid #F1F1F1'
			}}
		>
			<Grid
				container
				item
				xs
				alignItems="center"
				justifyContent="center"
				overflow="hidden"
			>
				<Grid container alignItems="center" justifyContent="center">
					<img
						src={fontSage}
						alt={title}
						width="108px"
						style={{ marginRight: '-19.5px' }}
					/>
					<img src={titleImage} alt={title} width="108px" />
					{/* <Slide
						direction="up"
						in={true}
						mountOnEnter
						unmountOnExit
						key={title}
					></Slide> */}
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs
				justifyContent="flex-end"
				position="absolute"
			>
				<Bar title={title} />
			</Grid>
		</Grid>
	);
}

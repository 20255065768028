import { Divider, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { SentimentIcon, get_dark_blue } from '../../Util/Helper';
import WidgetCard, { WidgetGrid } from './StatBlock';

import CalmCard from '../../quest/stats/weekly/breath_report/BreathReport';
import CoreThoughtsReport from '../../quest/stats/weekly/CoreThoughtsReport';
import DailyPulse from '../../quest/stats/daily/DailyPulse';
import GratitudesReport from '../../quest/stats/weekly/GratitudesReport';
import PrimaryEmotionsReport from '../../quest/stats/weekly/PrimaryEmotionsReport';
import { WeeklySentimentBar } from '../../quest/stats/weekly/vibe_check_report/VibeCheckReport';

function get_this_week_date(): string {
	const today = new Date();
	const monday = new Date(today);
	monday.setDate(today.getDate() - today.getDay() + 1);

	const sunday = new Date(monday);
	sunday.setDate(monday.getDate() + 6);

	const formatDate = (date: Date): string => {
		return date.toLocaleDateString('en-US', {
			month: 'short',
			day: 'numeric'
		});
	};

	return `${formatDate(monday)} - ${formatDate(sunday)}`;
}

const Quest: React.FC = () => {
	const [viewIndex, setViewIndex] = useState(1);
	const pages = ['Thoughts', 'Sage', 'Joy', 'Breaths'];
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [touchStart, setTouchStart] = useState<number>(0);

	const handleNext = () => {
		if (viewIndex < pages.length - 1) {
			setViewIndex(viewIndex + 1);
			scrollToPage(viewIndex + 1);
		}
	};

	const handlePrev = () => {
		if (viewIndex > 0) {
			setViewIndex(viewIndex - 1);
			scrollToPage(viewIndex - 1);
		}
	};

	const scrollToPage = (index: number) => {
		if (containerRef.current) {
			const scrollPosition = index * containerRef.current.clientWidth;
			containerRef.current.scrollTo({
				left: scrollPosition,
				behavior: 'smooth'
			});
		}
	};

	const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
		setTouchStart(e.touches[0].clientX);
	};

	const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
		const touchEnd = e.touches[0].clientX;
		const distance = touchStart - touchEnd;
		// Swipe left
		if (distance > 5) {
			handleNext();
		}
		// Swipe right
		else if (distance < -5) {
			handlePrev();
		}
	};
	const Scores = (
		<Grid
			container
			item
			spacing={1}
			direction="column"
			sx={{
				// height: '240px',
				marginTop: '8px',
				// padding: '20px',
				borderRadius: '24px',
				justifyContent: 'center',
				alignItems: 'center'
				// paddingTop: '16px'
			}}
		>
			<Grid container item>
				<WidgetCard
					selected={viewIndex === 1}
					onClick={() => setViewIndex(1)}
					title="Thoughts"
					value="38"
					icon={
						<SentimentIcon
							sentiment="all thoughts"
							size="24px"
							marginLeft="-2px"
						/>
					}
				/>
			</Grid>
			{/* <Divider orientation="vertical" /> */}
			<Grid container item>
				<WidgetCard
					selected={viewIndex === 3}
					onClick={() => setViewIndex(3)}
					title="Sage"
					icon={
						<SentimentIcon
							sentiment="sage"
							size="28px"
							marginLeft="-4px"
						/>
					}
					// color={get_cta_color()}
					value="10"
					status={['Wisdom', '', '10']}
				/>
			</Grid>
			<Grid container item>
				<WidgetCard
					selected={viewIndex === 0}
					title="Joy"
					onClick={() => setViewIndex(0)}
					icon={
						<SentimentIcon
							sentiment="joy"
							size="24px"
							// marginLeft="2px"
						/>
					}
					value="88%"
				/>
			</Grid>
		</Grid>
	);
	//   const
	const SageAi = (
		<Grid
			container
			item
			xs
			direction="column"
			style={{ borderRadius: '12px' }}
		>
			<Grid
				container
				item
				xs
				sx={{ backgroundColor: get_dark_blue(), borderRadius: '8px' }}
			>
				<Grid
					container
					item
					direction="column"
					sx={{
						padding: '16px',
						borderRadius: '8px'
					}}
				>
					<Grid container item xs>
						<SentimentIcon sentiment="sage_circle" size="40px" />
						<Typography
							sx={{
								color: 'white',
								fontSize: '24px',
								fontFamily: 'arial',
								fontWeight: 800,
								marginLeft: '8px'
							}}
						>
							Sage Report
						</Typography>
					</Grid>
					<Typography
						sx={{
							color: 'white',
							fontFamily: 'arial',
							fontSize: '14px',
							paddingLeft: '4px'
						}}
					>
						{get_this_week_date()}
					</Typography>
					{/* {Scores} */}

					<Typography
						sx={{
							color: 'white',
							fontSize: '14px',
							fontFamily: 'arial'
						}}
					>
						{<br />}
						<b>What happened this week to make you feel joyful: </b>
						{<br />}
						This week, you felt joy from moments like enjoying the
						sunshine during a walk, spending time with yourself by
						reading your book and drinking tea, and going into the
						office for in-person connections with coworkers. These
						small pleasures brought warmth and joy to your days.{' '}
						{<br />}
						{<br />}
						<b>
							What happened this week that made you feel harsh:{' '}
						</b>
						emotions/sentiment: {<br />}
						You faced some harsh emotions this week due to working
						long hours, feeling guilty about spending too much time
						scrolling social media, and having a tiny fight with
						Fiona. It’s understandable to feel overwhelmed and
						frustrated in these moments. Recognizing that it's okay
						to experience these feelings is important; and give
						yourself grace for spending too much time on social
						media - it happens to the best of us. What’s important
						is recognizing and trying your best to scale back.{' '}
						{<br />}
						{<br />}
						<b>
							Healthy actions to test next week to improve your
							emotional well-being:
						</b>
						{<br />} Incorporate Mindfulness: {<br />}Set aside 5-10
						minutes each day for mindfulness or meditation to help
						regain my sense of peace. {<br />}Tea and a Book: Each
						evening, spend some time reading and drinking tea. This
						made you joyful this week, and you should test to see if
						it helps with your mood next week.
						{<br />}Limit Screen Time: Try to reduce your social
						media usage to avoid feeling overwhelmed by external
						pressures. {<br />}
						{<br />}Remember to be kind to yourself as you move into
						the next week; it’s okay to take time to yourself and
						conflict with friends is natural, communication is how
						you grow in your relationships. Try to implement your
						healthy actions and check back in next week to see how
						these actions impacted your mood and overall well-being.
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);

	return (
		<Grid
			container
			item
			xs
			sx={{
				background: 'linear-gradient(145deg, #0f2636, #0c1a29)',
				// margin: '24px',
				padding: '24px',
				paddingBottom: '24px',
				// boxShadow:
				// 	'0px 0px 20px rgba(255, 255, 255, 0.2), 0px 0px 60px rgba(0, 0, 0, 0.5)',

				borderRadius: '16px',
				overflowY: 'auto',
				flexWrap: 'nowrap'
			}}
		>
			<Grid container item>
				<Grid container item xs direction="column">
					<Grid container item xs>
						<Grid
							container
							item
							xs={8}
							direction="column"
							marginRight="16px"
						>
							{SageAi}
						</Grid>
						<Grid
							container
							item
							xs
							direction="column"
							marginLeft="16px"
						>
							<Grid container item xs={3.5}>
								<DailyPulse date_range="Week" />
							</Grid>

							<Grid container item xs marginTop="16px">
								<WidgetGrid darkmode>
									<WeeklySentimentBar />
								</WidgetGrid>
							</Grid>
						</Grid>
					</Grid>

					{/* <Grid container item xs marginTop="24px">
						<Grid container item xs>
							<WidgetGrid darkmode>
								<CoreThoughtsReport date_range="Week" />{' '}
							</WidgetGrid>
						</Grid>
					</Grid> */}
					<Grid
						container
						item
						// xs
						marginTop="24px"
						marginBottom="24px"
						sx={{ backgroundColor: get_dark_blue() }}
						borderRadius="16px"
						// spacing={2}
					>
						<Grid container item xs marginRight="8px">
							<WidgetGrid darkmode>
								<GratitudesReport
									date_range="Week"
									sentiment="joy"
								/>
							</WidgetGrid>
						</Grid>
						<Grid container item xs marginRight="8px">
							<WidgetGrid darkmode>
								<PrimaryEmotionsReport date_range="Week" />
							</WidgetGrid>
						</Grid>
						<Grid container item xs>
							<WidgetGrid darkmode>
								<CalmCard date_range="Week" sentiment="joy" />{' '}
							</WidgetGrid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Quest;

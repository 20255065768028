import { Grid, Typography } from '@mui/material';
import { SentimentIcon, get_sentiment_color } from '../../../../Util/Helper';

export default function StatRatioMeter(props: {
	sorted_sentiment_data: [string, number][];
	total: number;
	text?: string;
	darkmode?: boolean;
	size?: string;
	bottom_border: boolean;
}) {
	// if (props.sorted_sentiment_data[0][1] === 0) {
	// 	// No Thoughts Logged
	// 	return (
	// 		<Grid
	// 			container
	// 			item
	// 			xs
	// 			alignItems="center"
	// 			borderBottom={props.bottom_border ? '0.3px solid white' : ''}
	// 		>
	// 			<Grid container item xs={1}>
	// 				<Typography
	// 					sx={{
	// 						fontFamily: 'arial',
	// 						fontWeight: 550,
	// 						fontSize: '14px',
	// 						color: 'white',
	// 						marginRight: '16px'
	// 					}}
	// 				>
	// 					{props.text}
	// 				</Typography>
	// 			</Grid>
	// 		</Grid>
	// 	);
	// }
	const icon_size = props.size === 'small' ? '16px' : '32px';
	return (
		<Grid
			container
			item
			xs
			alignItems="center"
			borderBottom={props.bottom_border ? '0.3px solid white' : ''}
		>
			<Grid container item xs={1}>
				<Typography
					sx={{
						fontFamily: 'arial',
						fontWeight: 550,
						fontSize: '14px',
						color: 'white',
						marginRight: '16px'
					}}
				>
					{props.text}
				</Typography>
			</Grid>
			<Grid
				container
				item
				xs={3}
				sx={{
					width: icon_size,
					height: icon_size
					// backgroundColor: 'red'
				}}
				// marginLeft="4px"
				justifyContent="center"
				alignItems="center"
				// marginRight="24px"
			>
				{props.sorted_sentiment_data[0][1] > 0 && (
					<SentimentIcon
						sentiment={props.sorted_sentiment_data[0][0]}
						size={icon_size}
					/>
				)}
			</Grid>
			<Grid
				container
				item
				xs
				justifyContent="flex-start"
				alignItems="center"
				// sx={{ backgroundColor: 'red' }}
			>
				<Grid
					container
					item
					xs
					// width="140px"
					height={props.size === 'small' ? '4px' : '24px'}
					borderRadius="24px"
					// margin="0px 16px"
					// marginTop="0px"
					sx={{
						backgroundColor: '#ccd2dc'
						// backgroundImage: `linear-gradient(to right, ${
						// 	get_sentiment_color('joy').color
						// } ${(joy_data[1] / props.total) * 100}%)`
					}}
				>
					{props.sorted_sentiment_data[0][1] > 0 && (
						<Grid
							container
							item
							xs={
								(props.sorted_sentiment_data[0][1] /
									props.total) *
								8
							}
							sx={{
								backgroundColor: get_sentiment_color(
									props.sorted_sentiment_data[0][0]
								).color,
								borderRadius: '8px'
							}}
						/>
					)}
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs={2}
				justifyContent="flex-end"
				alignItems="center"
			>
				{props.sorted_sentiment_data[0][1] > 0 && (
					<Typography
						sx={{
							fontWeight: 600,
							fontFamily: 'arial',
							fontSize: props.size === 'small' ? '12px' : '24px',
							// marginRight: '8px',
							color: get_sentiment_color(
								props.sorted_sentiment_data[0][0]
							).color
						}}
					>
						{props.sorted_sentiment_data[0][0]}
					</Typography>
				)}
			</Grid>
		</Grid>
	);
}

// import './LandingPage.css';

import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { LogoutRounded } from '@mui/icons-material';
import bottom_left_shape from '../../assets/images/svg-files/bottom_left_shape.svg';
import bottom_right_shape from '../../assets/images/svg-files/bottom_right_shape.svg';
import { get_cta_color } from '../../Util/Helper';
import gradient_circle from '../../assets/images/svg-files/gradient_circle.svg';
import top_left_shape from '../../assets/images/svg-files/top_left_shape.svg';
import { useAuth0 } from '@auth0/auth0-react';

export default function LandingPage() {
	const containerRef = useRef(null);

	const [collapsed, setCollapsed] = useState(false);
	const initialRef = useRef(null);
	const [state, setState] = useState(0);

	const handleScroll = () => {
		if (initialRef.current) {
			if (window.scrollY > 30) {
				if (!collapsed) {
					setCollapsed(true);
				}
				if (window.scrollY > 300) {
					setState(2);
				}
			} else {
				if (collapsed) {
					setCollapsed(false);
				}
				if (window.scrollY <= 300) {
					setState(0);
				}
			}
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [collapsed]);
	return (
		<Grid
			container
			item
			xs
			direction="column"
			ref={containerRef}
			alignItems="center"
			justifyContent="center"
			sx={{
				height: `100vh`,
				overflow: 'hidden',
				// backgroundImage:
				// 	'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)'
				backgroundColor: '#fffbf4'
				// backgroundSize: 'cover',
				// backgroundImage: `url(${landing_bg})` // Add background image if needed
				// backgroundImage:
				// 	'linear-gradient(145deg, rgba(255, 244, 225, 0.42), rgba(217, 240, 231, 0.42), rgba(255, 227, 227, 0.42))'
			}}
			style={{ cursor: 'default' }}
			position="relative"
		>
			<Grid
				container
				item
				xs
				position="absolute"
				top="50%"
				left="50%"
				borderRadius="50%"
				width="420px"
				height="420px"
				sx={{
					transform: 'translate(-50%, -50%)'
					// background:
					// 	'linear-gradient(90deg, #A6D5C1, #FDDEA5, #FFA6A5)'
				}}
			>
				<img src={gradient_circle} />
			</Grid>
			<Grid container item xs position="absolute" bottom="0" left="0">
				<img src={bottom_left_shape} />
			</Grid>
			<Grid
				container
				item
				xs
				position="absolute"
				bottom="0"
				right="0"
				width="480px"
			>
				<img src={bottom_right_shape} />
			</Grid>
			<Grid container item xs position="absolute" top="0" left="0">
				<img src={top_left_shape} />
			</Grid>
			<Grid
				container
				item
				xs
				position="absolute"
				top="16px"
				right="16px"
				width="226px"
			>
				<Grid
					container
					item
					xs
					sx={{
						// width: '210px',
						borderRadius: '16px',
						height: '60px',
						color: get_cta_color(),
						border: '2px solid ' + get_cta_color()
					}}
					alignItems="center"
					justifyContent="center"
				>
					<Typography
						component="a"
						href="https://join.sagethat.com"
						sx={{
							cursor: 'pointer',
							fontWeight: 50,
							fontSize: '20px',
							fontFamily: 'arial',
							color: get_cta_color(),
							textDecoration: 'none',
							'&:hover': { color: get_cta_color() }, // color on hover
							'&:active': { color: get_cta_color() }, // color when clicked
							'&:visited': { color: get_cta_color() } // color after visiting
						}}
						target="_blank"
						rel="noopener noreferrer"
					>
						Sign Up
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs
				justifyContent="center"
				alignItems="center"
				direction="column"
			>
				<Header />
			</Grid>
		</Grid>
		// {/* </Grid> */ }
	);
}

const Header = () => {
	const { loginWithRedirect } = useAuth0();
	const handleLogin = async () => {
		try {
			await loginWithRedirect({
				authorizationParams: {
					scope: 'openid profile email offline_access',
					redirectUri: `${window.location.origin}/auth/callback`
				}
			});
		} catch (error) {
			// Handle login error
			console.error('Login error:', error);
		}
	};
	return (
		<Grid
			container
			item
			// xs
			justifyContent="center"
			alignItems="center"
			sx={{
				paddingRight: '24px',
				paddingLeft: '24px',
				// paddingTop: '24px',
				height: '80px'
			}}
		>
			<Button
				onClick={handleLogin}
				variant="outlined"
				// endIcon={<ChevronRight />}
				sx={{
					position: 'absolute',
					// marginTop: '32px',
					width: '620px',
					height: '80px',
					borderRadius: '40px',
					// height: '54px',
					backgroundColor: 'white',
					fontSize: '16px',
					lineHeight: '22px',
					fontWeight: 450,
					borderColor: get_cta_color(),
					// color: get_cta_color(),

					fontFamily: 'arial',
					border: '1 px solid' + get_cta_color(),
					// marginTop: '48px',
					textTransform: 'none',
					borderWidth: '4px',
					'&:hover': {
						borderColor: get_cta_color(),
						borderWidth: '4px',
						backgroundColor: 'white'
					}
				}}
			>
				<Typography
					sx={{
						fontSize: '32px',
						fontWeight: 550,
						// fontFamily: 'arial',
						marginRight: '24px',
						color: get_cta_color()
					}}
				>
					Enter Sage Quest
				</Typography>
				<LogoutRounded
					sx={{
						color: get_cta_color(),
						width: '32px',
						height: '32px'
					}}
				/>
				{/* <img src={sageCircle} width="120px" /> */}
			</Button>
		</Grid>
	);
};
export const GreenOutlineButton = (props: {
	onClick: any;
	text: string;
	width?: string;
	height?: string;
}) => {
	return (
		<Button
			onClick={props.onClick}
			sx={{
				width: props.width || undefined,
				height: props.height || undefined,
				textTransform: 'none',
				backgroundColor: 'white',
				border: '1px solid #74A899',
				boxShadow: '#74A899',
				radius: '12px'
			}}
		>
			<Typography
				sx={{
					color: '#74A899',
					fontSize: '14px',
					fontWeight: 450,
					lineHeight: '20px',
					spacing: '-0.18px'
				}}
			>
				{props.text}
			</Typography>
		</Button>
	);
};

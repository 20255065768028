import './index.css';

import App from './app/app';
import { Auth0Provider } from '@auth0/auth0-react';
import ErrorBoundary from './components/ErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/styles';
import reportWebVitals from './reportWebVitals';
import theme from './Util/Theme';

ReactDOM.render(
	<Auth0Provider
		useRefreshTokens={true}
		authorizationParams={{
			redirectUri: `${window.location.origin}/space/gringotts`,
			audience: 'https://dev-jlcvb3a4okf2ouva.us.auth0.com/api/v2/',
			response_type: 'code',
			grant_type: 'client_code',
			scope: 'openid profile email offline_access'
		}}
		useRefreshTokensFallback={false}
		domain="dev-jlcvb3a4okf2ouva.us.auth0.com"
		clientId="AESxPXirpHW9sFkXv6jCtv9wHhfz2G6x"
	>
		<ThemeProvider theme={theme}>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</ThemeProvider>
	</Auth0Provider>,
	document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

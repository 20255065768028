import { Alert, Button, TextField, Typography } from '@mui/material';
import { ButtonPrimary, ButtonSecondary } from '../../Util/Components/Button';
import React, { useEffect } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { fetchUser, setAuthenticated } from '../../Util/Redux/Middleware';
import { useDispatch, useSelector } from 'react-redux';

import { FormControl } from '@mui/material';
import Grid from '@mui/material/Grid';
import { LoginText } from '../../Util/Components/Typography';
import { RootState } from '../../Util/Redux/Schema';
import { StaticDatePicker } from '@mui/lab';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import sageCircle from '../../assets/images/logo/sageCircle.svg';
import sageLogo from '../../assets/images/sageLogo.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export function LoginSignUpButton(props: {
	text: string;
	marginLeft?: string;
	marginBottom?: string;
	onClick?: () => void;
}) {
	return (
		<Button
			fullWidth={props.fullWidth}
			onClick={() => props.onClick && props.onClick()}
			sx={{
				borderRadius: '140px',
				marginLeft: props.marginLeft,
				marginBottom: props.marginBottom,
				width: '280px',
				height: '88px',
				'&:hover': {
					backgroundImage:
						'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)'
				},
				// backgroundColor: get_cta_color(),
				backgroundImage:
					'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)',
				textTransform: 'none'
			}}
		>
			<Typography
				sx={{
					fontSize: '20px',
					lineHeight: '20px',
					fontWeight: 600,
					color: '#333333',
					letterSpacing: '-0.05px',
					fontFamily: 'arial'
				}}
			>
				{props.text}
			</Typography>
		</Button>
	);
}
function SizeErrorPage() {
	return (
		<Grid
			container
			item
			xs
			justifyContent="center"
			alignItems="center"
			height="100vh"
			direction="column"
			sx={{
				backgroundImage:
					'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)'
			}}
		>
			<Grid
				container
				item
				xs={11}
				justifyContent="center"
				alignItems="center"
				direction="column"
				padding="8px"
				// width="400px"
				// height="452px"
			>
				<Grid
					container
					item
					justifyContent="center"
					marginBottom="24px"
				>
					<img
						src={sageCircle}
						style={{ width: '76.22', height: '64px' }}
					/>
				</Grid>
				<Typography
					sx={{
						fontWeight: 600,
						fontSize: '12px',
						lineHeight: '40px',
						letterSpacing: '-3%',
						marginBottom: '48px',
						textAlign: 'center',
						color: '#2c2c2c'
					}}
				>
					We are actively working on optimizing our site for this
					screen size! In the meantime, please continue your Sage
					Quest on a laptop or larger device. THANK YOU for your
					patience 🥺
				</Typography>
				{/* <LoginSignUpButton
					text="Log in or Sign up"
					onClick={handleLogin}
				/> */}
			</Grid>

			{/* <Grid
				container
				item
				xs
				justifyContent="space-between"
				alignItems="flex-end"
				padding="24px"
			>
				<Typography
					sx={{
						fontSize: '14px',
						color: '#4F4D55',
						fontWeight: 400,
						lineHeight: '20px',
						letterSpacing: '-0.05px'
					}}
				>
					{' '}
					©2024 Sage{' '}
				</Typography>
				<Typography
					sx={{
						fontSize: '14px',
						color: '#4F4D55',
						fontWeight: 400,
						lineHeight: '20px',
						letterSpacing: '-0.05px'
					}}
				>
					{' '}
					Privacy Policy{' '}
				</Typography>
			</Grid> */}
		</Grid>
	);
}

export default SizeErrorPage;

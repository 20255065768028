import { Grid, SvgIconTypeMap } from '@mui/material';
import {
	SentimentIcon,
	get_dark_blue,
	get_sentiment_color,
	parse_sage_thoughts,
	parse_thoughts,
	split_thoughts_by_sentiment
} from '../../../Util/Helper';

import DailyWidgetCard from './DailyWidgetCard';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { RootState } from '../../../Util/Redux/Schema';
import { useSelector } from 'react-redux';

export type WellbeingComponent = {
	text: string;
	icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
	color: string;
	value: string;
};
export const DailyPulse = (props: { date_range: 'Day' | 'Week' | 'Month' }) => {
	const current_month_thoughts = useSelector(
		(state: RootState) => state.thoughts.current_month_thoughts
	);
	const current_month_sage_thoughts = useSelector(
		(state: RootState) => state.sageThoughts
	);
	const thoughts = parse_thoughts(props.date_range, current_month_thoughts);
	const sage_count = Object.keys(
		parse_sage_thoughts(props.date_range, current_month_sage_thoughts)
	).length;
	const thought_sentiment = split_thoughts_by_sentiment(thoughts);
	const harsh_thoughts = thought_sentiment.NEGATIVE;
	const harsh_count = harsh_thoughts.length;
	const joy_count = thought_sentiment.POSITIVE.length;
	const breaths = useSelector(
		(state: RootState) => state.pulseOfPlanet.breath_count
	);

	const wellbeing_components: WellbeingComponent[] = [
		{
			text: 'Thoughts',
			icon: <SentimentIcon sentiment="all thoughts" size="28px" />,
			color: {
				dark: get_sentiment_color('harsh').color,
				light: '#F7D1DC'
			},
			value: thoughts.length
		},
		{
			text: 'Sage',
			icon: <SentimentIcon sentiment="sage" size="32px" />,
			color: {
				dark: get_sentiment_color('sage').color,
				light: '#E1F0E7'
			},
			value:
				Math.min(
					100,
					Math.ceil(
						(harsh_count === 0 ? 0 : sage_count / harsh_count) * 100
					)
				) + '%'
		},

		{
			text: 'Joy',
			icon: <SentimentIcon sentiment="joy" size="30px" />,
			color: {
				dark: get_sentiment_color('joy').color,
				light: '#FBF5D1'
			},
			value:
				(joy_count + harsh_count === 0
					? 0
					: Math.min(
							100,
							Math.ceil(joy_count / (joy_count + harsh_count)) *
								100
					  )) + '%'
		}
	];

	return (
		<Grid
			container
			item
			height="100%"
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			{wellbeing_components.map((component) => (
				<DailyWidgetCard
					title={component.text}
					value={component.value}
					icon={component.icon}
					selected={false}
					// margin={true}
					onClick={() => {}}
				/>
			))}
		</Grid>
	);
};
export default DailyPulse;

import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { CloudRounded, TimelineRounded } from '@mui/icons-material';
import { get_cta_color, get_sentiment_color } from '../../../Util/Helper';

import { GradientCloudIcon } from '../../../assets/svgFiles';
import MonthDropdown from '../../../components/organisms/MonthDropdown';
import cloud_gradient from '../../../assets/images/svg-files/cloud_gradient.svg';

const Filters = ({
	sentiment_idx,
	on_change_sentiment_idx,
	descending,
	on_change_descending,
	month_view,
	on_change_month_view
}: {
	sentiment_idx: number;
	on_change_sentiment_idx: () => void;
	descending: boolean;
	on_change_descending: () => void;
	month_view: string;
	on_change_month_view: (month: string) => void;
}) => {
	return (
		<>
			<FilterRow>
				<Grid container item marginLeft="20px" alignItems="center">
					<Grid
						container
						item
						alignItems="center"
						style={{
							borderRadius: '50%',
							marginLeft: '2px',
							marginRight: '10px',
							marginTop: '-8px',
							cursor: 'pointer',
							alignItems: 'center'
						}}
					>
						<IconButton
							onClick={on_change_sentiment_idx}
							style={{
								width: '42px',
								height: '42px'
							}}
						>
							{sentiment_idx === 0 ? (
								// <TimelineRounded />
								// <GradientCloudIcon />
								<img
									src={cloud_gradient}
									width="24px"
									style={{ marginLeft: '-4px' }}
								/>
							) : (
								<CloudRounded
									style={{
										color:
											sentiment_idx === 0
												? '#F3F2F3'
												: sentiment_idx === 1
												  ? get_sentiment_color(
															'positive'
												    ).color
												  : sentiment_idx === 2
												    ? get_sentiment_color(
																'negative'
												      ).color
												    : '#E0E0E0'
									}}
								/>
							)}
						</IconButton>
						{/* <MonthDropdown
							selected_month={month_view}
							on_change_selected_month={on_change_month_view}
							sentiment_idx={sentiment_idx}
						/> */}
						<Grid
							container
							item
							xs
							// justifyContent="flex-end"
							alignItems="center"
							sx={{ height: '36px', marginLeft: '8px' }}
						>
							<Tooltip
								title={
									descending
										? 'Newest to Oldest'
										: 'Oldest to Newest'
								}
							>
								<Button
									sx={{
										// width: '24px',
										height: '36px',
										borderRadius: '36px',
										textTransform: 'none',

										// backgroundImageColor: 'lightgray',
										// get_cta_color(),
										color: 'white',
										'&:hover': {
											backgroundColor: get_cta_color(0.1)
										}
										// padding: '4px'
									}}
									onClick={on_change_descending}
								>
									<Typography
										sx={{
											color: 'gray',
											fontSize: '12px',
											fontFamily: 'arial'
										}}
									>
										{/* Sort by:{' '} */}
										{descending
											? 'Newest to Oldest'
											: 'Oldest to Newest'}
									</Typography>
									<TimelineRounded
										sx={{
											marginLeft: '8px',
											width: '16px',
											height: '16px',
											color: get_cta_color()
										}}
									/>
								</Button>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			</FilterRow>
		</>
	);
};

export function FilterRow(props: { children: React.ReactElement }) {
	const { children } = props;
	return (
		<Grid container item alignItems="center" marginTop="0px">
			{children}
		</Grid>
	);
}

export default Filters;

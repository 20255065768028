import { Grid, Typography } from '@mui/material';
import {
	SentimentIcon,
	get_sentiment_color,
	get_text_color
} from '../../Util/Helper';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

import { FavoriteRounded } from '@mui/icons-material';
import GratitudeBar from './GratitudeBar';
import { RootState } from '../../Util/Redux/Schema';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#2f4150',
		width: '240px',
		// height: '240px',
		color: '#fff',
		fontSize: '14px',
		padding: '16px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		textAlign: 'left'
	}
});
const TooltipContent = (props: { gratitudes: string[] }) => (
	<Grid container item xs direction="column">
		<Typography
			sx={{
				fontFamily: 'arial',
				color: 'white',
				fontSize: '16px',
				marginBottom: '8px'
			}}
		>
			I am grateful for...
		</Typography>
		<Grid container xs item direction="column">
			{props.gratitudes.map((gratitude) => (
				<Grid container item xs alignItems="center">
					<Grid container item marginBottom="8px">
						<FavoriteRounded
							sx={{
								color: get_sentiment_color('harsh').color,
								width: '16px',
								height: '16px',
								marginRight: '8px'
							}}
						/>
						<Grid container item xs>
							<Typography
								sx={{
									color: 'white',
									fontSize: '12px',
									fontFamily: 'arial'
								}}
							>
								{gratitude}
							</Typography>
						</Grid>
					</Grid>{' '}
				</Grid>
			))}
		</Grid>
	</Grid>
);

export default function GratitudeInput() {
	const gratitudes = useSelector(
		(state: RootState) => state.pulseOfPlanet.gratitudes
	);
	const gratitude_count = gratitudes.length;

	return (
		<Grid
			container
			item
			xs={2}
			marginBottom="24px"
			direction="column"
			alignItems="flex-start"
			justifyContent="flex-start"
		>
			<CustomTooltip
				title={
					<TooltipContent
						gratitudes={
							gratitudes.length === 0
								? ['Enter your first gratitude']
								: gratitudes
						}
					/>
				}
				placement="left"
			>
				<Grid
					container
					item
					alignItems="center"
					marginBottom="8px"
					sx={{ cursor: 'default' }}
				>
					<SentimentIcon sentiment="heart" size="12px" />
					<Typography
						sx={{
							color: '#2f2f2f',
							fontSize: '12px',
							fontFamily: 'arial'
						}}
					>
						Gratitudes: {gratitude_count}
					</Typography>
				</Grid>
			</CustomTooltip>
			<GratitudeBar />
		</Grid>
	);
}

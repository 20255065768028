import { Grid, TextareaAutosize } from '@mui/material';
import React, { useRef, useState } from 'react';

import { AppDispatch } from '../../../Util/Redux/Schema';
import { addThought } from '../../../Util/Redux/Middleware';
import { getTimestamp } from '../../../Util/Helper';
import { postThought } from '../../../Util/Redux/AsyncMiddleware';
import { useDispatch } from 'react-redux';

const ThoughtBar = () => {
	const [message, setMessage] = useState(
		localStorage.getItem('chatbarMessage') || ''
	);
	const dispatch = useDispatch<AppDispatch>();
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(event.target.value);
		localStorage.setItem('chatbarMessage', event.target.value);
	};
	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault(); // Prevent the default Enter behavior (line break)
			dispatch(
				addThought({
					created_at: getTimestamp(),
					sentiment: 'unknown',
					thought: message,
					computed_top_emotions: [],
					uuid: null,
					user_selected_emotions: [],
					reframed: ''
				})
			);
			dispatch(postThought(message));
			setMessage('');
			localStorage.removeItem('chatbarMessage');
		} else if (event.key === 'Enter' && event.shiftKey) {
			event.preventDefault(); // Prevent the default Enter behavior (line break)
			setMessage(message + '\n'); // Add a newline character to the message
			localStorage.setItem('chatbarMessage', message + '\n');
		}
	};
	const textareaRef = useRef();

	return (
		<Grid
			container
			item
			justifyContent="space-between"
			width="100%"
			alignItems="center"
			sx={{
				backgroundColor: '#F8F8F8',
				borderRadius: '32px',
				padding: '8px 16px'
			}}
		>
			<Grid container item xs>
				<TextareaAutosize
					ref={textareaRef}
					placeholder={"What's on your mind?"}
					value={message}
					maxRows={10}
					style={{
						fontFamily: 'arial',
						color: '#0A090B',
						fontWeight: 450,
						backgroundColor: 'transparent',
						fontSize: '14px',
						lineHeight: '20px',
						letterSpacing: '-0.05px',
						width: '100%',
						border: 'none',
						outline: 'none',
						resize: 'none',
						minHeight: '32px',
						paddingTop: '12px'
					}}
					onChange={handleChange}
					onKeyPress={handleKeyPress}
					autoFocus
				/>
			</Grid>
		</Grid>
	);
};

export default ThoughtBar;

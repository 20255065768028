import { Grid, Typography } from '@mui/material';
import {
	get_cta_color,
	get_emotion_group_data,
	get_sentiment_color,
	parse_thoughts,
	parse_weekly_thoughts
} from '../../../Util/Helper';

import { CloudRounded } from '@mui/icons-material';
import { FullPieChart } from '../../thought/stats/RainbowPieChart';
import { RootState } from '../../../Util/Redux/Schema';
import { useSelector } from 'react-redux';

export default function PrimaryEmotionsReport(props: { date_range: string }) {
	const month_thoughts = useSelector(
		(state: RootState) => state.thoughts.current_month_thoughts
	);
	const thoughts = parse_thoughts('Month', month_thoughts);
	const data = get_emotion_group_data(thoughts);
	return (
		<>
			<Grid container item xs={1} marginBottom="16px" direction="column">
				<Typography
					sx={{
						fontSize: '16px',
						lineHeight: '20px',
						fontFamily: 'arial',
						color: 'whitesmoke',
						fontWeight: 600,
						cursor: 'default'
					}}
				>
					<b
						style={{
							color: 'white',
							marginRight: '2px',
							fontSize: '16px'
							// marginLeft: '4px'
						}}
					>
						Primary Emotions
					</b>
				</Typography>
			</Grid>
			<Grid
				container
				item
				xs
				justifyContent="center"
				alignItems="center"
				padding="0px"
				// style={{ height: '100%' }}
			>
				<Grid
					container
					item
					xs={7}
					alignItems="center"
					justifyContent="center"
					sx={{
						borderRadius: '50%',
						paddingRight: '16px'
					}}
				>
					<Grid
						container
						item
						sx={{
							height: '180px',
							width: '180px'
						}}
					>
						{/* <Grid container item xs> */}
						<FullPieChart data={data} />
					</Grid>
					{/* </Grid> */}
				</Grid>
				{/* <Grid
					container
					item
					xs
					alignItems="center"
					justifyContent="center"
					direction="column"
					sx={{
						backgroundColor: 'white',
						padding: '8px',
						borderRadius: '8px',
						height: '210px',
						marginLeft: '8px'
					}}
				>
					{data.map(
						(d) =>
							d.value > 0 && (
								<Grid container item xs alignItems="center">
									<CloudRounded
										sx={{
											color: d.color
										}}
									/>
									<Typography
										sx={{
											fontSize: '12px',
											color: '#2f2f2f',
											fontFamily: 'arial',
											marginLeft: '8px'
										}}
									>
										{' '}
										{d.label}
									</Typography>
									<Grid
										container
										item
										xs
										justifyContent="flex-end"
									>
										<Typography
											sx={{
												fontSize: '12px',
												color: '#2f2f2f',
												fontFamily: 'arial',
												marginLeft: '8px'
											}}
										>
											{d.value}%
										</Typography>
									</Grid>
								</Grid>
							)
					)}
				</Grid> */}
			</Grid>
		</>
	);
}
export function ProgressCountInsights() {
	const thoughts = useSelector((state: RootState) =>
		parse_weekly_thoughts(state.thoughts.current_month_thoughts)
	);
	const currentDate = new Date();
	const currentDay = (currentDate.getDay() - 1 + 7) % 7; // 0 is Monday, ..., 5 is Saturday, 6 is Sunday
	const daysInWeek = 7;
	const startOfWeek = new Date(currentDate);
	startOfWeek.setDate(currentDate.getDate() - currentDay); // Move to the beginning of the week (Monday)
	const totalPositive = thoughts.filter(
		(thought) => thought.sentiment !== 'negative'
	).length;
	const totalNegative = thoughts.length - totalPositive;

	function Stat(props: {
		paddingTop?: boolean;
		count: number;
		color: string;
		type: 'Joyful' | 'Harsh';
	}) {
		const colors = get_sentiment_color(props.type);

		return (
			<Grid
				container
				item
				// xs
				alignItems="center"
				justifyContent="center"
				paddingTop={props.paddingTop ? '16px' : undefined}
				// sx={{ backgroundColor: 'red' }}
			>
				<Grid
					container
					item
					// xs={8}
					// xl={6}
					// sx={{ backgroundColor: 'red' }}
					alignItems="center"
					justifyContent="center"
				>
					<Grid container item xs alignItems="center">
						<Grid
							container
							item
							style={{
								width: '24px',
								height: '24px',
								backgroundColor: props.color,
								borderRadius: '4px',
								padding: '4px'
							}}
						/>
						<Typography
							sx={{
								color: '#4F4D55',
								fontSize: '14px',
								lineHeight: '24px',
								fontWeight: 600,
								marginLeft: '8px'
							}}
						>
							{props.type}
							{/* </b> {props.count} */}
							{/* thought {props.count > 1 && 's'} so far this week */}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		);
	}
	return (
		<Grid
			container
			item
			// marginTop="24px"
			// xs
			// direction="column"
			// height="310px"

			// alignItems="center"
			// sx={{ backgroundColor: 'red' }}
		>
			{/* <Typography>s */}
			<Grid container item xs>
				{data.slice(0, 4).map((d) => (
					<Stat count={d.value} type={d.label} color={d.color} />
				))}
			</Grid>
			<Grid container item xs>
				{data.slice(4).map((d) => (
					<Stat count={d.value} type={d.label} color={d.color} />
				))}
			</Grid>
		</Grid>
	);
}

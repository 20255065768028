import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { Grid } from '@mui/material';

export function RainbowPieChart(props: { data: any }) {
	const { data } = props;
	return <></>;
	return (
		<PieChart
			// marginLeft={40}
			width={330}
			height={300}
			display={false}
			slotProps={{
				legend: {
					hidden: true // This hides the legend
				}
			}}
			series={[
				{
					data: data,
					innerRadius: 80,
					outerRadius: 100,
					paddingAngle: 3,
					cornerRadius: 5,
					startAngle: -90,
					endAngle: 90,
					cx: 150,
					cy: 150
				}
			]}
		/>
	);
}
const data01 = [
	{
		name: 'Group A',
		value: 400
	},
	{
		name: 'Group B',
		value: 300
	},
	{
		name: 'Group C',
		value: 300
	},
	{
		name: 'Group D',
		value: 200
	},
	{
		name: 'Group E',
		value: 278
	},
	{
		name: 'Group F',
		value: 189
	}
];
const data02 = [
	{
		name: 'Group A',
		value: 2400
	},
	{
		name: 'Group B',
		value: 4567
	},
	{
		name: 'Group C',
		value: 1398
	},
	{
		name: 'Group D',
		value: 9800
	},
	{
		name: 'Group E',
		value: 3908
	},
	{
		name: 'Group F',
		value: 4800
	}
];

export function FullPieChart(props: { data: any }) {
	const { data } = props;
	return (
		<Grid
			container
			item
			xs
			width="100%"
			height="100%"
			sx={
				{
					// backgroundColor: 'rgb(255, 255, 255, 0.9)',
					// borderRadius: '50%'
				}
			}
		>
			<ResponsiveContainer
				width="100%"
				height="100%"
				style={{ backgroundColor: 'white', borderRadius: '50%' }}
			>
				<PieChart width={800} height={800}>
					<Tooltip />
					<Pie
						data={props.data}
						dataKey="value"
						nameKey="label"
						cx="50%"
						cy="50%"
						// outerRadius={50}
						// fill="#8884d8"
						innerRadius={40}
						// outerRadius={80}
					/>
					{/* <Pie
					data={data02}
					dataKey="value"
					nameKey="name"
					cx="50%"
					cy="50%"
					innerRadius={60}
					outerRadius={80}
					fill="#82ca9d"
					label
				/> */}
				</PieChart>
			</ResponsiveContainer>
			{/* <PieChart
				// sx={{ backgroundColor: 'red' }}
				// marginLeft={40}]
				margin={{ top: -45, left: -45 }}
				// width={260}
				// height={260}
				// height={120}
				display={false}
				slotProps={{
					legend: {
						hidden: true // This hides the legend
					}
				}}
				tooltip={{
					// Use PopperProps to set the zIndex of the tooltip
					PopperProps: {
						disablePortal: true // Ensures the tooltip is rendered inside the modal

						//   modifiers: [
						//     {
						//       name: "zIndex",
						//       enabled: true,
						//       phase: "write",
						//       fn: ({ state }) => {
						//         state.styles.popper.zIndex = 10000000; // Higher than modal (default modal zIndex is 1300)
						//       },
						//     },
						//   ],
					}
				}}
				series={[
					{
						data: data,
						innerRadius: 20,
						outerRadius: 100,
						paddingAngle: 2,
						cornerRadius: 5,
						// startAngle: -90,
						// endAngle: 90,
						cx: 150,
						cy: 150
					}
				]}
			/> */}
		</Grid>
	);
}

import {
	Add,
	Remove,
	StarRounded,
	TipsAndUpdatesRounded
} from '@mui/icons-material';
import {
	AppDispatch,
	Bias,
	RootState,
	SageThought,
	Thought
} from '../../../Util/Redux/Schema';
import {
	Button,
	Chip,
	Grid,
	Modal,
	Skeleton,
	Tooltip,
	Typography
} from '@mui/material';
import {
	COGNITIVE_BIAS_DESCRIPTOR,
	get_cta_color,
	get_sage_thought,
	get_sentiment_color
} from '../../../Util/Helper';
import { useDispatch, useSelector } from 'react-redux';

import Breathwork from '../../mindfulness/BreathCircle';
import CustomTextField from '../../../components/molecules/CustomTextField';
import sageCircle from '../../../assets/images/logo/sageCircle.svg';
import sagethat from '../../../assets/images/logo/sagethat.png';
import { update_user_reframed_thought } from '../../../Util/Redux/AsyncMiddleware';
import { useState } from 'react';

export const Header = (props: { pageIdx: number }) => {
	return (
		<Grid container item justifyContent="space-between" alignItems="center">
			<img
				src={sageCircle}
				style={{ height: '48px', width: '44px', marginRight: '8px' }}
			/>
			<img src={sagethat} style={{ height: '32px', width: '140px' }} />
			<Grid
				container
				item
				xs
				justifyContent="flex-end"
				paddingRight="24px"
			/>
		</Grid>
	);
};
export const SageWorksheet = (props: {
	open: boolean;
	setOpen: (value: boolean) => void;
	thought: Thought;
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const [pageIdx, setPageIdx] = useState(0);

	const sage_thought = useSelector((state: RootState) =>
		get_sage_thought(state.sageThoughts, props.thought.uuid)
	);
	const [reframed_thought, setReframedThought] = useState(
		sage_thought?.user_reframed_thought || ''
	);

	const onClick = () => {
		setPageIdx(pageIdx + 1);
	};
	const [openIdx, setOpenIdx] = useState(0);
	const [completed, setCompleted] = useState<boolean[]>([
		false,
		false,
		false,
		false
	]);
	const setGenerateJoyCompleted = (isCompleted: boolean) => {
		setCompleted(
			completed.map((c: boolean, idx: number) =>
				idx === 2 ? isCompleted : c
			)
		);
	};
	const setOpenIdxAndCompleted = (idx: number) => {
		setOpenIdx(idx);
		if (idx !== 0 && !completed[0]) {
			setCompleted(completed.map((c, idx) => (idx === 0 ? true : c)));
		}
		if (openIdx === 1 && idx !== 1 && !completed[1]) {
			setCompleted(completed.map((c, idx) => (idx === 1 ? true : c)));
		}
	};
	const onSubmitWorksheet = () => {
		props.setOpen(false);
		dispatch(
			update_user_reframed_thought(
				reframed_thought,
				sage_thought.uuid,
				props.thought.uuid
			)
		);
	};
	return (
		<Modal
			open={props.open}
			onClose={() => props.setOpen(false)}
			sx={{ zIndex: 2000, backdropFilter: 'blur(5px)' }}
		>
			<Grid
				container
				style={{
					padding: '32px',
					width: '680px',
					height: '680px',
					borderRadius: '16px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: 'white',
					overflowY: 'auto'
				}}
				direction="column"
			>
				<Grid
					container
					item
					xs
					direction="column"
					justifyContent="center"
				>
					<Header pageIdx={pageIdx} />

					{pageIdx === 0 && (
						<ViewThought thought={props.thought.thought} />
					)}
					<Grid
						container
						item
						xs
						padding="16px"
						paddingLeft={0}
						borderRadius="12px"
						justifyContent="center"
					>
						{pageIdx === 0 ? (
							<Grid container item direction="column">
								<Assess
									biases={sage_thought?.biases || ''}
									open={true}
									setOpen={() => setOpenIdxAndCompleted(1)}
									completed={completed[1]}
								/>
								<GenerateJoy
									userReframedThought={reframed_thought}
									setUserReframedThought={setReframedThought}
									cosReframedThought={
										sage_thought?.reframed_thought || ''
									}
									completed={completed[2]}
									setCompleted={setGenerateJoyCompleted}
									thoughtUuid={props.thought.uuid}
								/>
							</Grid>
						) : (
							<Embrace
								sageThought={sage_thought}
								onClose={onSubmitWorksheet}
							/>
						)}

						<Grid
							container
							item
							justifyContent="center"
							marginTop="24px"
							xs
						>
							{pageIdx === 0 && (
								<SubmitButton
									onClick={onClick}
									disabled={!reframed_thought}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Modal>
	);
};

const ViewThought = (props: { thought: string }) => {
	return (
		<Grid container item alignItems="center" margin="8px 0px">
			<Tooltip
				PopperProps={{ style: { zIndex: 5000 } }}
				title={props.thought}
			>
				<Typography
					sx={{
						fontSize: '14px',
						lineHeight: '20px',
						fontFamily: 'arial',
						fontWeight: 600,
						color: '#7F7D83',
						cursor: 'default',
						textDecoration: 'underline'
					}}
				>
					View your harsh thought
				</Typography>
			</Tooltip>
		</Grid>
	);
};

const BodyTypography = (props: { children: React.ReactElement | string }) => {
	return (
		<Typography
			sx={{
				fontSize: '14px',
				lineHeight: '20px',
				fontFamily: 'arial',
				fontWeight: 400,
				color: '#7F7D83',
				cursor: 'default'
			}}
		>
			{props.children}
		</Typography>
	);
};

const Assess = (props: {
	biases: Bias[];
	open: boolean;
	setOpen: () => void;
	completed: boolean;
}) => {
	const patterns = props.biases
		? props.biases.map((bias) => [bias.name, bias.reason])
		: [
				[
					'Social Proof',
					"While not explicitly stated, there may be an underlying pressure to perform all aspects of the project well, which can create resistance when faced with tasks that don't align with your natural strengths. This can slow down progress or lead to avoidance."
				],
				[
					'Negativity Bias',
					'You express uncertainty about your creative abilities in marketing, labeling them as "not my strong suit." This kind of thinking can undermine confidence and contribute to resistance, making the tasks seem harder than they might actually be.'
				],
				[
					'Overgeneralization',
					'By identifying creativity in marketing as challenging, there may be a tendency to overgeneralize that struggle across other creative tasks, which can amplify feelings of resistance.'
				]
		  ];
	const body = (
		<Grid
			container
			item
			// xs
			marginTop="12px"
			direction="column"
			paddingBottom="8px"
			sx={{ backgroundColor: '#F2F5F9', height: '140px' }}
			borderRadius="8px"
			padding="24px"
		>
			<Grid
				container
				item
				// xs
				alignItems="center"
				sx={{
					backgroundColor: '#F2F5F9',
					// padding: "24px",
					borderRadius: '8px',
					marginBottom: '16px'
				}}
			>
				{' '}
				<Skeleton width="160px" />
				<Skeleton
					width="80px"
					height="24px"
					sx={{ borderRadius: '16px', marginLeft: '8px' }}
				/>
			</Grid>
			{/* <Skeleton /> */}
			{/* <Grid container item xs direction="column"> */}
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
		</Grid>
	);
	const body2 = (
		<Grid container marginTop="12px" direction="column" paddingBottom="8px">
			<Grid
				container
				alignItems="center"
				sx={{
					backgroundColor: '#F2F5F9',
					padding: '24px',
					borderRadius: '8px',
					marginBottom: '16px'
				}}
			>
				<Grid container direction="column">
					{patterns.map((type, idx) => {
						return (
							<Grid
								container
								item
								direction="row"
								alignItems="center"
								marginTop={idx && '24px'}
							>
								<Grid container item xs={4} width="140px">
									<Tooltip
										title={
											COGNITIVE_BIAS_DESCRIPTOR[type[0]]
										}
										PopperProps={{
											modifiers: [
												{
													name: 'zIndex',
													enabled: true,
													phase: 'afterWrite',
													fn: ({ state }) => {
														state.styles.popper.zIndex = 15000; // Set a higher zIndex than the modal (default is 1300)
													}
												}
											]
										}}
									>
										<Chip
											label={type[0]}
											style={{
												width: '140px',
												height: '36px',
												color: 'white',
												backgroundColor:
													get_cta_color(),
												cursor: 'default'
											}}
										/>
									</Tooltip>
								</Grid>
								<Grid container item xs>
									<Typography
										sx={{
											fontSize: '16px',
											fontWeight: 450,
											fontColor: '#2c2c2c',
											fontFamily: 'arial',
											marginTop: '8px'
										}}
									>
										{type[1]}
									</Typography>
								</Grid>
							</Grid>
						);
					})}
				</Grid>
			</Grid>
		</Grid>
	);
	return (
		<Template
			title="Assess your thought patterns"
			body={props.biases ? body2 : body}
		/>
	);
};

const Template = (props: { startIcon?: any; title: string; body: any }) => {
	return (
		<>
			<Grid container item xs width="100%" alignItems="center">
				{props.startIcon}
				<Typography
					sx={{
						fontSize: '16px',
						lineHeight: '20px',
						fontFamily: 'arial',
						fontWeight: 400,
						color: '#7F7D83',
						cursor: 'default'
					}}
				>
					<b
						style={{
							color: get_cta_color(),
							fontSize: '20px',
							marginLeft: '4px'
						}}
					>
						{props.title[0]}
					</b>
					{props.title.slice(1, props.title.length)}
				</Typography>
			</Grid>
			{props.body}
		</>
	);
};

const GenerateJoy = (props: {
	userReframedThought: string;
	setUserReframedThought: (userReframedThought: string) => void;
	cosReframedThought: string;
	completed: boolean;
	setCompleted: (completed: boolean) => void;
	thoughtUuid: string;
}) => {
	const body2 = (
		<Grid
			container
			padding="16px"
			direction="column"
			marginTop="8px"
			borderRadius="8px"
			sx={{
				backgroundColor: '#F2F5F9',
				height: '210px',
				border: '1px solid #EBEBEB'
			}}
		>
			<Grid container item xs width="100%" alignItems="center">
				<Grid container item xs>
					<Typography
						sx={{
							fontSize: '16px',
							lineHeight: '20px',
							fontFamily: 'arial',
							fontWeight: 400,
							color: '#7F7D83',
							cursor: 'default',
							marginBottom: '24px'
						}}
					>
						Example:{' '}
						{props.cosReframedThought ||
							"I have my own unique preferences, and it's okay if they differ from others."}
					</Typography>
				</Grid>
			</Grid>
			{/* </Grid> */}
			<CustomTextField
				placeholder="A more positive way to think about this is..."
				value={props.userReframedThought}
				setValue={props.setUserReframedThought}
				setCompleted={props.setCompleted}
				backgroundColor="white"
			/>
		</Grid>
		// </Grid>
	);
	const body = (
		<Grid
			container
			paddingLeft="4px"
			direction="column"
			marginTop="8px"
			borderRadius="8px"
			sx={{
				backgroundColor: '#F2F5F9',
				height: '140px',
				border: '1px solid #EBEBEB'
			}}
		>
			<Grid
				container
				item
				// xs
				marginTop="4px"
				alignItems="center"
				// sx={{ backgroundColor: 'red' }}
			>
				<Typography
					sx={{
						color: '#adacb0',
						fontSize: '14px',
						margin: '8px',
						fontFamily: 'arial',
						marginLeft: '16px'
						// marginTop: '8px'
					}}
				>
					Example:
				</Typography>
				<Skeleton animation="wave" style={{ width: '240px' }} />
			</Grid>
		</Grid>
	);
	const body3 = (
		<Grid
			container
			item
			// xs
			marginTop="12px"
			direction="column"
			paddingBottom="8px"
			sx={{ backgroundColor: '#F2F5F9', height: '140px' }}
			borderRadius="8px"
			padding="24px"
		>
			<Grid
				container
				item
				// xs
				alignItems="center"
				sx={{
					backgroundColor: '#F2F5F9',
					// padding: "24px",
					borderRadius: '8px',
					marginBottom: '16px'
				}}
			>
				{' '}
				<Skeleton width="160px" />
				<Skeleton
					width="80px"
					height="24px"
					sx={{ borderRadius: '16px', marginLeft: '8px' }}
				/>
			</Grid>
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
		</Grid>
	);
	return (
		<Grid container marginTop="24px">
			<Template
				startIcon={
					<StarRounded
						sx={{ color: get_sentiment_color('reframed').color }}
					/>
				}
				title="Generate a reframed thought"
				body={props.cosReframedThought ? body2 : body}
			/>
		</Grid>
	);
};

const Title = (props: {
	title: string;
	open: boolean;
	completed: boolean;
	setOpen: () => void;
	disabled?: boolean;
}) => {
	const [hover, setHover] = useState(false);
	return (
		<Grid
			container
			onClick={props.setOpen}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			marginTop="16px"
			borderRadius="8px"
			justifyContent="center"
			alignItems="center"
			paddingLeft="4px"
			sx={
				{
					// cursor: props.disabled ? 'default' : 'pointer',
					// backgroundColor:
					// 	hover && !props.disabled ? '#F1F1F1' : 'transparent'
				}
			}
		>
			<Typography
				sx={{
					fontSize: '12px',
					lineHeight: '20px',
					fontFamily: 'arial',
					fontWeight: 400,
					color: '#7F7D83',
					cursor: 'default'
				}}
			>
				<b style={{ color: get_cta_color(), fontSize: '20px' }}>
					{props.title}
				</b>
				{/* {props.title.slice(1, props.title.length)} */}
			</Typography>
			<Grid container item xs justifyContent="flex-end"></Grid>
		</Grid>
	);
};

const Embrace = (props: { sageThought: SageThought; onClose: () => void }) => {
	// const
	const body = (
		<Grid
			container
			item
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			{[
				props.sageThought.healthy_suggestions
				// "Remember, an emotional reaction lasts no more than 120 seconds (15 full breaths)",
				// "Sit up straight. Body posture influences emotional states; Good posture enhances mood and emotional well-being",
				// "Now, Sage that thought. Let go",
				// "You are 30% more productive when you smile",
				// "Intentionally send deep breathes to any lingering feelings of discomfort in your body.",
			].map((tip) => (
				<Grid container item xs marginTop="16px">
					<Grid container item xs={0.5} alignItems="center">
						<TipsAndUpdatesRounded
							style={{
								marginRight: '4px',
								color: get_cta_color(),
								borderRadius: '50%'
								// padding: '4px'
							}}
						/>
					</Grid>
					<Grid container item xs alignItems="center">
						<Typography
							sx={{
								fontSize: '14px',
								fontFamily: 'arial',
								color: '#858580',
								// lineHeight: '24px',
								marginTop: '-16px',
								paddingBottom: '8px',
								marginLeft: '4px'
							}}
						>
							<br />
							{tip}
						</Typography>
					</Grid>
				</Grid>
			))}

			<Breathwork maxCount={500} size="large" onClose={props.onClose} />
		</Grid>
	);
	return <Template title="Elevate your mind" body={body} />;
};

const SubmitButton = (props: {
	onClick: () => void;
	disabled: boolean;
	cancel?: boolean;
}) => {
	return (
		<Button
			onClick={props.onClick}
			disabled={props.disabled}
			sx={{
				'&:hover': {
					backgroundColor: props.cancel ? 'white' : get_cta_color(),
					textDecoration: props.cancel ? 'underline' : undefined
				},
				width: '276px',
				height: '42px',
				marginTop: '8px',
				textTransform: 'none',
				textDecoration: props.cancel ? 'underline' : undefined,

				':disabled': {
					backgroundColor: props.cancel ? 'white' : '#E6E6E6'
				},
				backgroundColor: props.cancel ? 'white' : get_cta_color(),
				color: props.cancel ? '#2f2f2f' : 'white',
				cursor: 'pointer'
				//   marginBottom: "48px",
			}}
		>
			{props.cancel ? 'Skip' : 'Submit'}
		</Button>
	);
};

export const BreathCountSelector = () => {
	const [breaths, setBreaths] = useState(5);
	return (
		<Grid
			container
			item
			alignItems="center"
			width="276px"
			height="64px"
			marginBottom="4px"
			borderRadius="12px"
		>
			<Grid
				container
				item
				justifyContent="center"
				alignItems="center"
				onClick={() => setBreaths(breaths > 1 ? breaths - 1 : breaths)}
				sx={{
					width: '40px',
					height: '40px',
					border: '1px solid #F1F1F1',
					borderRadius: '12px',
					cursor: breaths > 1 ? 'cursor' : 'not-allowed'
				}}
			>
				<Remove
					sx={{
						width: '20px',
						height: '20px',
						color: breaths > 1 ? get_cta_color() : '#DCDCDE'
					}}
				/>
			</Grid>
			<Grid
				container
				item
				justifyContent="center"
				alignItems="center"
				sx={{
					marginLeft: '24px',
					marginRight: '24px',
					width: '148px',
					height: '48px',
					borderRadius: '12px',
					border: '1px solid #F1F1F1'
				}}
			>
				<input
					value={breaths}
					onChange={(e) => setBreaths(parseInt(e.target.value))}
					style={{
						width: '100px',
						fontSize: '24px',
						lineHeight: '28px',
						fontWeight: 500,
						textAlign: 'center',
						border: 'none'
					}}
				></input>
			</Grid>
			<Grid
				container
				item
				onClick={() => setBreaths(breaths + 1)}
				justifyContent="center"
				alignItems="center"
				sx={{
					width: '40px',
					height: '40px',
					border: '1px solid #F1F1F1',
					borderRadius: '12px',
					cursor: 'cursor'
				}}
			>
				<Add
					sx={{
						width: '20px',
						height: '20px',
						color: get_cta_color()
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default SageWorksheet;

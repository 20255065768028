import { Grid, Typography } from '@mui/material';

export default function ThoughtEntryTemplate(props: {
	icon: any;
	iconArcher?: any;
	thought: string;
	header: any;
	indentIcon?: boolean;
	body?: any;
}) {
	return (
		<Grid
			container
			item
			xs
			sx={{
				marginTop: '16px',
				height: '100%',
				// backgroundColor: 'red',
				paddingRight: '16px'
				// paddingLeft: '16px'
			}}
		>
			<Grid
				container
				item
				xs={1}
				justifyContent="center"
				alignItems="flex-start"
				paddingTop="8px"
			>
				{!props.indentIcon && props.icon}
			</Grid>

			<Grid container item xs alignItems="flex-start">
				{props.indentIcon && props.icon}
				<Grid
					container
					item
					xs
					width="100%"
					direction="column"
					alignItems="flex-start"
				>
					<Grid container item xs direction="column">
						{props.header}
					</Grid>
					<Grid container item xs width="100%" alignItems="center">
						<Typography
							sx={{
								cursor: 'default',
								fontFamily: 'arial',
								color: '#2c2c2c',
								fontSize: '14px',
								lineHeight: '20px',
								overflow: 'hidden'
							}}
						>
							{props.thought}
						</Typography>
					</Grid>
					<Grid container item xs>
						{props.body}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

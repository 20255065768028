import { getDarkGreen, get_dark_blue } from "./Helper";

export const centeredGridStyles = {
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  border: "1px solid #F1F1F1",
};

export const dash_grid_styles = {
  border: "1px solid #F1F1F1",
  backgroundColor: "white",
  alignItems: "center",
  justifyContent: "flex-start",
  alignContent: "flex-start",
};

export const dash_title_typography_styles = {
  cursor: "default",
  fontFamily: "arial",
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: 550,
  color: getDarkGreen(),
  //  '#345247'
};

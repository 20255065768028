import {
	AirRounded,
	FavoriteRounded,
	RocketRounded
} from '@mui/icons-material';
import { Grid, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
	get_cta_color,
	get_dark_blue,
	get_sage_thought,
	get_sentiment_color,
	parse_daily_thoughts
} from '../../Util/Helper';

import Breathwork from '../../quest/mindfulness/BreathCircle';
import { CheckinEditView } from './CheckinEditView';
import { DailyPulse } from '../../quest/stats/daily/DailyPulse';
import MindfulMoments from '../../quest/mindfulness/MindfulMoments';
import Quest from '../organisms/QuestMain';
import { RootState } from '../../Util/Redux/Schema';
import bottom_left_shape from '../../assets/images/svg-files/bottom_left_shape.svg';
import bottom_right_shape from '../../assets/images/svg-files/bottom_right_shape.svg';
import gradient_circle from '../../assets/images/svg-files/gradient_circle.svg';
import rocket from '../../assets/images/svg-files/rocket.svg';
import skyBg from '../../assets/images/svg-files/skyBg.svg';
import top_left_shape from '../../assets/images/svg-files/top_left_shape.svg';
import { useSelector } from 'react-redux';

export const Wellbeing = (props: { showStatsView?: boolean }) => {
	return (
		<Grid
			container
			direction="column"
			item
			xs
			justifyContent="space-between"
			sx={{ borderRadius: '8px' }}
		>
			<Grid
				container
				item
				sx={{
					boxShadow: '0px 0px 4px 4px' + 'white',
					borderRadius: '16px',
					marginBottom: '16px'
				}}
				direction="column"
			>
				<Grid
					container
					item
					// xs={4}
					direction="column"
					sx={{
						// width: '320px',
						height: '210px',
						borderRadius: '16px',
						// backgroundColor: get_dark_blue()
						backgroundColor: 'white',
						backgroundImage:
							'linear-gradient(145deg, rgba(255, 244, 225, 0.42), rgba(217, 240, 231, 0.42), rgba(255, 227, 227, 0.42))'
					}}
					padding="0 8px"
				>
					<Grid
						container
						item
						xs
						direction="column"
						alignItems="center"
						sx={{
							borderRadius: '8px',
							padding: '16px 8px',
							display: 'flex'
						}}
						position="relative"
					>
						{/* <Grid container item xs alignItems="center">
							<Typography
								sx={{
									cursor: 'default',
									fontFamily: 'arial',
									fontSize: '12px',
									// lineHeight: "24px",
									color: get_dark_blue(),
									marginLeft: '8px'
									// fontWeight: '600'
								}}
							>
								Today's Pulse Check
							</Typography>
						</Grid> */}
						{/* <img
							src={gradient_circle}
							width="180px"
							height="180px"
						/> */}
						{/* <Grid container item position="absolute" top="50%">
							<Grid
								container
								item
								// xs
								border={'2px solid ' + get_cta_color()}
								borderRadius="24px"
								height="40px"
								alignItems="center"
								justifyContent="center"
								sx={{ backgroundColor: 'white' }}
							></Grid>
						</Grid> */}
						<Grid container item xs alignItems="center">
							<DailyPulse date_range="Day" />
						</Grid>

						{/* <DailyPulse date_range="Day" /> */}
					</Grid>
				</Grid>
			</Grid>

			<MindfulMoments />
		</Grid>
	);
};

export default Wellbeing;

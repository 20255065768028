import { Grid, Typography } from '@mui/material';

import { Cloud } from '@mui/icons-material';
import { DateRange } from '../../../Util/Helper';

export default function ThoughtsEmptyState(props: { date_range: DateRange }) {
	return (
		<Grid
			container
			item
			xs
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			<Grid
				container
				item
				alignItems="center"
				justifyContent="center"
				sx={{
					borderRadius: '16px'
					// backgroundColor: '#F8F8F8',
					// width: '56px',
					// height: '56px'
				}}
			>
				<Cloud
					sx={{ color: '#dcdcde', width: '40px', height: '40px' }}
				/>
			</Grid>
			{/* <Typography
				marginTop="16px"
				sx={{
					fontWeight: 450,
					fontSize: '16px',
					lineHeight: '22px',
					letterSpacing: '-0.18px',
					color: '#4F4D55'
				}}
			>
				Your ether is empty…
			</Typography> */}
			<Typography
				marginTop="4px"
				sx={{
					fontWeight: 450,
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '-0.05px',
					color: '#7F7D83'
				}}
			>
				Share your first thought of the {props.date_range.toLowerCase()}
				!
			</Typography>
		</Grid>
	);
}

import { Grid, Typography } from '@mui/material';

import Breathwork from './Breathwork';
import { CheckinEditView } from '../../archived/GratitudesCheckin';
import GratitudeInput from './GratitudeInput';

export default function MindfulMoments() {
	return (
		<Grid
			container
			item
			xs
			direction="column"
			borderRadius="16px"
			sx={{ backgroundColor: 'white' }}
			border="1px solid #F3F2F3"
			padding="16px"
		>
			<Grid
				container
				item
				xs
				direction="column"
				justifyContent="space-between"
			>
				<Typography
					sx={{
						cursor: 'default',
						fontFamily: 'arial',
						fontSize: '12px',
						lineHeight: '24px',
						color: '#345247',
						marginLeft: '4px',
						marginBottom: '8px'
					}}
				>
					Pause & Refresh: Mindful Moments
				</Typography>
				<Grid
					container
					item
					xs
					marginTop="8px"
					justifyContent="space-between"
					direction="column"
				>
					<Grid
						container
						item
						xs={3}
						marginBottom="8px"
						direction="column"
					>
						<GratitudeInput />
					</Grid>
					{/* <CheckinEditView /> */}
					<Breathwork />
				</Grid>
			</Grid>
		</Grid>
	);
}

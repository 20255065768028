import { Button, Fade, Grid, Typography } from '@mui/material';
import { Theme, createStyles } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { AppDispatch } from '../../Util/Redux/Schema';
import { get_cta_color } from '../../Util/Helper';
import { makeStyles } from '@mui/styles';
import { post_breath_count } from '../../Util/Redux/AsyncMiddleware';
import { useDispatch } from 'react-redux';

export default function BreathCircle(props: {
	maxCount?: number;
	size: string;
	onClose?: () => void;
	initText: string;
}) {
	const [progress_count, setProgressCount] = useState(null);
	return (
		<Grid
			container
			item
			xs
			alignItems="center"
			direction="column"
			sx={{
				justifyContent: 'center',
				paddingTop: '8px',
				borderRadius: '8px'
			}}
		>
			<Typography
				sx={{
					cursor: 'default',
					fontFamily: 'arial',
					fontSize: '14px',
					color: '#ADACB0',
					marginLeft: '12px',
					marginTop: '8px',
					fontWeight: 450,
					backgroundColor: 'transparent',
					lineHeight: '20px'
				}}
			>
				{progress_count
					? `${progress_count} conscious breaths`
					: props.initText || ''}
			</Typography>
			<Grid
				container
				item
				xs
				// direction="column"
				// sx={{ backgroundColor: "red" }}
			>
				<BreathingCircle
					progress_count={progress_count}
					setProgressCount={setProgressCount}
					maxCount={props.maxCount || 8}
					size={props.size}
					onClose={props.onClose}
				/>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		circle: {
			alignItems: 'center',
			justifyContent: 'center',
			position: 'absolute',
			backgroundColor: '#E9EBF8',
			// backgroundImage: `radial-gradient(circle, #F2F6FF, #C4DEFF, #D6E5FE)`,
			// get_sentiment_color('reframed').backgroundColor,
			display: 'flex'
		},
		center_circle: {
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			position: 'absolute',
			// backgroundColor: "white",
			// backgroundColor: "#C4DEFF",
			backgroundColor: '#8E9BB6',
			// border: `4px solid ${get_cta_color()}`,
			// get_sentiment_color('reframed').color,
			zIndex: 1
		},
		animated_circle: {
			// backgroundColor: "#D6E5FE",
			backgroundColor: '#C2D0F1',
			// backgroundColor: get_sentiment_color("reframed").color,
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},

		inhale: {
			animation: '$inhaleAnimation 5s ease-in-out forwards',
			animationPlayState: (props: { isPlaying: boolean | null }) =>
				props.isPlaying ? 'running' : 'paused'
		},

		exhale: {
			animation: '$exhaleAnimation 5s ease-in-out forwards',
			animationPlayState: (props: { isPlaying: boolean | null }) =>
				props.isPlaying ? 'running' : 'paused'
		},

		'@keyframes inhaleAnimation': {
			'0%': {
				transform: 'scale(.75)'
			},
			'100%': {
				transform: 'scale(1)'
			}
		},
		'@keyframes exhaleAnimation': {
			'0%': {
				transform: 'scale(1)'
			},
			'100%': {
				transform: 'scale(.75)'
			}
		}
	})
);

export const BreathingCircle = (props: {
	progress_count: null | number;
	setProgressCount: (count: null | number) => void;
	maxCount: number;
	size: string;
	onClose?: () => void;
}) => {
	const [isPlaying, setIsPlaying] = useState<boolean | null>(null);
	const classes = useStyles({ isPlaying: isPlaying });
	const [circleState, setCircleState] = useState<null | 'inhale' | 'exhale'>(
		null
	);
	const [counter, setCounter] = useState(5);
	const { progress_count, setProgressCount, maxCount, size, onClose } = props;
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		if (counter === null || isPlaying === false) {
			return;
		}
		if (counter <= 5) {
			const intervalId = setInterval(() => {
				setCounter((prevCounter) => prevCounter + 1);
			}, 1000);

			// Clear the interval when the component is unmounted or when counter reaches 0
			return () => clearInterval(intervalId);
		} else {
			setTimeout(() => {
				if (!isPlaying) {
					return;
				}
				setCircleState((prev) => {
					// setCounter(4);
					return prev === 'inhale' ? 'exhale' : 'inhale';
				});
				// setVisible((prev) => !prev);
				setCounter(1);
				if (circleState === 'exhale') {
					setProgressCount(progress_count + 1);
					if (progress_count === maxCount) {
						setIsPlaying(false);
						setProgressCount(null);
					}
				}
			}, 100);
		}
	}, [counter, setCircleState]);

	const onStart = () => {
		setIsPlaying(true);
		setProgressCount(1);
		setCounter(1);
		setCircleState('inhale');
	};

	const onContinue = () => {
		setIsPlaying(true);
	};

	const onStop = () => {
		setIsPlaying(null);
		setProgressCount(null);
		setCounter(null);
		setCircleState(null);
		const final_breath_count =
			circleState === 'exhale' ? progress_count : progress_count - 1;
		if (final_breath_count > 0) {
			dispatch(post_breath_count(final_breath_count));
		}
		if (onClose) {
			onClose();
		}
	};

	return (
		<Grid
			container
			item
			xs
			justifyContent="center"
			direction="column"
			alignItems="center"
			sx={{
				borderRadius: '8px',
				position: 'relative'
				// backgroundColor: "white",
			}}
		>
			<Button
				disableRipple
				fullWidth
				onClick={
					isPlaying === null
						? onStart
						: isPlaying === false
						  ? onContinue
						  : onStop
				}
				sx={{
					height: size === 'large' ? '360px' : '190px',

					width: size === 'large' ? '360px' : '190px',
					borderRadius: '50%',
					'&:hover': {
						backgroundColor: 'transparent'
					}
				}}
			>
				<Grid
					className={classes.circle}
					sx={{
						height: size === 'large' ? '290px' : '190px',
						width: size === 'large' ? '290px' : '190px',
						borderRadius: '50%'
					}}
				/>
				<Grid
					className={classes.center_circle}
					justifyContent="center"
					alignItems="center"
					sx={{
						width: '75px',
						height: '75px',
						borderRadius: '50%'
						// backgroundColor:
						// 	isPlaying === null ? '#C2D0F1' : '#8D9BB6'
					}}
				>
					{isPlaying === null && (
						<Button>
							<Typography
								sx={{
									color: 'white',
									textTransform: 'none',
									fontSize: '16px',
									fontFamily: 'arial',
									fonWeight: 550
								}}
							>
								breathe
							</Typography>
						</Button>
					)}
					{isPlaying === true && counter > 0 && counter < 6 && (
						<Fade in={true} timeout={3000}>
							<Typography
								sx={{
									color: 'white',
									fontSize: '12px',
									fontWeight: 500,
									textAlign: 'center',
									lineHeight: '72px'
								}}
							>
								{circleState}
							</Typography>
						</Fade>
					)}
				</Grid>
				{circleState === null && (
					<Grid
						className={`${classes.animated_circle}`}
						sx={{
							// width: '150px',\
							width: size === 'large' ? '242px' : '145px',
							height: size === 'large' ? '242px' : '145px',
							borderRadius: '50%',
							animationPlayState: isPlaying ? 'running' : 'paused'
						}}
					/>
				)}
				{circleState === 'inhale' && (
					<Grid
						className={`${classes.animated_circle} ${classes.inhale}`}
						sx={{
							// width: '150px',\
							width: size === 'large' ? '290px' : '190px',
							height: size === 'large' ? '290px' : '190px',
							borderRadius: '50%',
							animationPlayState: isPlaying ? 'running' : 'paused'
						}}
					/>
				)}

				{circleState === 'exhale' && (
					<Grid
						// elevation={3}
						className={`${classes.animated_circle} ${classes.exhale}`}
						sx={{
							width: size === 'large' ? '290px' : '190px',
							height: size === 'large' ? '290px' : '190px',
							borderRadius: '50%',
							animationPlayState: isPlaying ? 'running' : 'paused'
						}}
					/>
				)}
			</Button>
		</Grid>
	);
};

export const ActionButton = (props: {
	text: string;
	onClick: () => any;
	endIcon?: Element;
}) => {
	return (
		<Button
			onClick={props.onClick}
			style={{
				height: '32px',
				textTransform: 'none',
				backgroundColor: get_cta_color(),
				borderRadius: '16px'
			}}
			variant="contained"
			endIcon={props.endIcon}
		>
			<Typography>{props.text}</Typography>
		</Button>
	);
};

import { Box, Grid, Stack, Typography } from '@mui/material';
import { JSXElementConstructor, ReactElement } from 'react';

interface WidgetCardProps {
	title: string;
	icon?: React.ReactNode;
	selected?: boolean;
	status?: string[];
	color: string;
	onClick: () => void;
	value?: string;
}

export function WidgetGrid(props: {
	marginTop?: string;
	marginLeft?: string;
	color?: string;
	children: ReactElement<any, string | JSXElementConstructor<any>>;
	darkmode?: boolean;
}) {
	return (
		<Grid
			container
			item
			xs
			direction="column"
			sx={{
				marginLeft: props.marginLeft && '8px',
				marginTop: props.marginTop && '8px',
				// background: 'linear-gradient(145deg, #0f2636, #0c1a29)',
				// backgroundColor: 'whitesmoke',
				// boxShadow: 'inset 2px 2px 8px white, inset -2px -2px 8px white',
				borderRadius: '12px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
				// gap: '10px',
				// transition: 'transform 0.2s ease-in-out',
				// '&:hover': {
				// 	transform: 'scale(1.05)',
				// 	boxShadow: '0 0 15px rgba(0, 173, 255, 0.6)'
				// }
			}}
		>
			<Grid
				container
				item
				xs
				direction="column"
				sx={{
					backgroundColor: props.darkmode ? '#2f4150' : 'white',
					borderRadius: '8px',
					padding: '16px'
				}}
			>
				{props.children}
			</Grid>
		</Grid>
	);
}
const WidgetCard: React.FC<WidgetCardProps> = ({
	title,
	icon,
	onClick,
	value
}) => {
	return (
		<Grid
			container
			item
			xs
			// direction="column"
			sx={{
				borderRadius: '12px',
				padding: '4px 4px'
				// backgroundColor: 'red'
				// display: 'flex',
				// flexDirection: 'column',
				// justifyContent: 'space-between',
				// transition: 'transform 0.2s ease-in-out'
			}}
			onClick={onClick}
		>
			<Grid
				container
				item
				xs
				direction="row"
				// justifyContent="space-between"
				alignItems="center"
			>
				{icon}
				{/* <Typography sx={{ color: 'white' }}>{value}</Typography> */}
				<Typography
					variant="h6"
					sx={{
						marginLeft: '16px',
						fontFamily: 'arial',
						fontSize: '16px',
						fontWeight: 800,
						color: 'white'
					}}
				>
					{value} {title}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default WidgetCard;

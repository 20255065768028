import { Grid, Typography } from '@mui/material';
import {
	dash_grid_styles,
	dash_title_typography_styles
} from '../../../Util/Styles';
import { getToday, parse_daily_thoughts } from '../../../Util/Helper';

import React from 'react';
import { RootState } from '../../../Util/Redux/Schema';
import ThoughtBar from './ThoughtBar';
import ThoughtList from './ThoughtList';
import { useSelector } from 'react-redux';

export const ThoughtsEditor: React.FC = () => {
	const boxRef = React.useRef<HTMLDivElement | null>(null);
	const current_month_thoughts = useSelector(
		(state: RootState) => state.thoughts.current_month_thoughts
	);
	const thoughts = parse_daily_thoughts(current_month_thoughts);
	return (
		<Grid
			container
			item
			xs={8}
			sx={dash_grid_styles}
			direction="column"
			marginRight="8px"
			borderRadius="16px"
			ref={boxRef}
			// sx={{backgroundColor:}}
			padding="36px 0"
		>
			<Grid
				container
				item
				xs
				direction="column"
				sx={{ padding: '0 40px' }}
			>
				<Typography
					sx={dash_title_typography_styles}
					marginBottom="-8px"
				>
					{getToday()}
				</Typography>
				<Grid
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
					item
					xs
				>
					<ThoughtList thoughts={thoughts} date_range="Day" mutable />

					<ThoughtBar />
				</Grid>
			</Grid>
			{/* )} */}
		</Grid>
	);
};

export default ThoughtsEditor;
